<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Orgao" />
            <h1>{{ orgaoForm.id ? "Editar" : "Novo" }} Órgão</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>                
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-1">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="orgaoForm.nome" />
                            </div>
                            <div class="item">
                                <label><b>Sigla:</b></label>
                                <input type="text" placeholder="Digite a sigla" v-model="orgaoForm.sigla" />
                            </div>
                        </div> 
                        <div class="flexbox-3">
                            {{ this.setarOptions() }}
                            <div class="item">
                                <label for="selectEsfera"><b>{{ possuiAsterisco("Esfera:") }}</b></label>
                                <select v-model="orgaoForm.esfera">
                                    <option :value="orgaoForm.esfera" disabled selected hidden>{{ orgaoForm.esfera }}</option>
                                    <option
                                        v-for="esfera in optionsEsferas"
                                        :key="esfera"
                                        :value="esfera"
                                    >
                                        {{ esfera }}
                                    </option>
                                </select>
                            </div>
                            <div class="item">
                                <label for="selectPoder"><b>{{ possuiAsterisco("Poder:") }}</b></label>
                                <select v-model="orgaoForm.poder">
                                    <option :value="orgaoForm.poder" disabled selected hidden>{{ orgaoForm.poder }}</option>
                                    <option
                                        v-for="poder in optionsPoderes"
                                        :key="poder"
                                        :value="poder"
                                    >
                                        {{ poder }}
                                    </option>
                                </select>
                            </div>
                            <div class="item">
                                <label for="selectUf"><b>{{ possuiAsterisco("UF:") }}</b></label>
                                <v-select
                                    class="vselect" 
                                    v-model="orgaoForm.ufs_id" 
                                    id="selectUf" 
                                    :options="ufs"
                                    :reduce="uf => uf.id" 
                                    label="uf" 
                                    placeholder="Todos">
                                </v-select>
                            </div>
                        </div>
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ orgaoForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import OrgaoApi from "../../services/OrgaoApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    export default {
        name: "OrgaoForm",
        props: {
            orgao: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                count: -1,
                count1: null,
                filess: null,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    sigla: '',
                    esfera: '',
                    poder: '',
                    ufs_id:'',
                    default: '',
                },
                esferas: [],
                poderes: [],
                ufs: [],
                optionsEsferas: [],
                optionsPoderes: [],
                orgaoForm: this.orgao
                    ? {
                        id: this.orgao.id,
                        nome: this.orgao.nome,
                        sigla: this.orgao.sigla,
                        esfera: this.orgao.esfera,
                        poder: this.orgao.poder,
                        ufs_id: this.orgao.ufs_id,
                    }
                    : {
                        id: null,
                        nome: null,
                        sigla: null,
                        esfera: null,
                        poder: null,
                        ufs_id: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
            vSelect,
        },
        created() {
            this.orgaoApi = new OrgaoApi();
            this.carregarEsferas();
            this.carregarPoderes();
            this.carregarUfs();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            async carregarEsferas() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosEsferas = await this.orgaoApi.getEsferas();
                    this.esferas = dadosEsferas
                } catch (err) {
                    console.log("carregarEsferas", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarPoderes() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosPoderes = await this.orgaoApi.getPoderes();
                    this.poderes = dadosPoderes
                } catch (err) {
                    console.log("carregarPoderes", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarUfs() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    const dadosUfs = await this.orgaoApi.getUfs();
                    this.ufs = dadosUfs
                } catch (err) {
                    console.log("carregarUfs", err);

                    this.carregando = false;
                    this.erro = true;
                }
            },
            setarOptions() {
                const POSICAO_ZERO = 0;
                for (let i = 0; i < this.esferas.length; i++) {
                    this.optionsEsferas[i] = this.esferas[i][POSICAO_ZERO];
                }
                for (let i = 0; i < this.poderes.length; i++) {
                    this.optionsPoderes[i] = this.poderes[i][POSICAO_ZERO];
                }
            },
            retornarIdEsfera(esferas) {
                const POSICAO_ZERO = 0;
                var esferaOrgao1 = this.orgaoForm.esfera;

                for (var i = 0; i < esferas.length; i++) {
                    if (esferas[i][POSICAO_ZERO] == esferaOrgao1) {
                        var id = i;
                    }
                }

                return id
            },
            retornarIdPoder(poderes) {
                const POSICAO_ZERO = 0;
                var poderOrgao1 = this.orgaoForm.poder;

                for (var i = 0; i < poderes.length; i++) {
                    if (poderes[i][POSICAO_ZERO] == poderOrgao1) {
                        var id = i;
                    }
                }

                return id
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },
            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;

                let flag = this.validarForm(this.orgaoForm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    this.msgErros = '';

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.orgaoApi.cancelTokens.setOrgao) {
                        this.orgaoApi.cancelTokens.setOrgao.cancel();
                    }

                    if (this.orgaoForm.esfera !== 0 || this.orgaoForm.esfera !== 1 || this.orgaoForm.esfera !== 2 || this.orgaoForm.esfera !== 3) {
                        this.orgaoForm.esfera = this.retornarIdEsfera(this.esferas)
                    }
                    if (this.orgaoForm.poder !== 0 || this.orgaoForm.poder !== 1 || this.orgaoForm.poder !== 2 || this.orgaoForm.poder !== 3) {
                        this.orgaoForm.poder = this.retornarIdPoder(this.poderes)
                    }
                    let data;
                    data = await this.orgaoApi.setOrgao(
                        this.orgaoForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "OrgaoShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                    //window.location.reload();
                } catch (err) {
                    if (this.orgaoApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(orgaoForm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.sigla = ''
                this.msgErros.esfera = ''
                this.msgErros.poder = ''
                this.msgErros.ufs_id = ''
                this.msgErros.default = ''

                //Nome não pode estar em branco
                if (orgaoForm.nome == null || orgaoForm.nome == '') {
                    this.msgErros.nome = "O campo nome não foi preenchido.";
                    flag = 1;
                }

                //Sigla não pode estar em branco
                if (orgaoForm.sigla == null || orgaoForm.sigla == '') {
                    this.msgErros.sigla = "O campo sigla não foi preenchido.";
                    flag = 1;
                }

                //Esfera não pode estar em branco
                if (orgaoForm.esfera == null || orgaoForm.esfera == '') {
                    this.msgErros.esfera = "O campo esfera não foi preenchido.";
                    flag = 1;
                }

                //Uf não pode estar em branco
                if (orgaoForm.ufs_id == null || orgaoForm.ufs_id == '') {
                    this.msgErros.ufs_id = "O campo uf não foi preenchido.";
                    flag = 1;
                }
                
                //Poder não pode estar em branco
                if (orgaoForm.poder == null || orgaoForm.poder == '') {
                    this.msgErros.poder = "O campo poder não foi preenchido.";
                    flag = 1;
                }

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
            formatarCPF(cpf) {
                // Lógica para formatar o CPF (exemplo: 123.456.789-01)
                return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
            },
        },

    };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
    * {
        margin: 0;
        padding: 0;
        font-family: 'Karla', sans-serif;
    }

    #tipo-convenio {
        visibility: hidden;
    }

    #faixa-convenio {
        visibility: hidden;
    }

    @media screen and (min-width: "1066px") {
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            box-shadow: #005516 0px 5px 15px;

            .flexbox-1 {
                display: grid;
                grid-template-columns: 2fr 1fr;
                gap: 2rem;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 1rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 1.5fr 1.5fr;
                gap: 1.5rem;
            }

            input,
            the-mask {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
            input[type="file"] {
                display: none;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: grid;
                margin: .5rem 0;
                width: -webkit-fill-available;

                .radio {
                    display: inline-flex;
                    align-items: center;
                    height: fit-content;
                    width: fit-content;
                }

                .radio-label {
                    margin-bottom: 0;
                }

                .radio-item {
                    display: flex;
                    align-items: center;

                    label {
                        margin: 0 0 0 .8rem;
                        font-size: .8rem;
                    }

                    input {
                        width: fit-content;
                        margin-left: .5rem;
                    }
                }

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                /*  .upload {
                            color: #ffa600;
                            border-radius: 0.5rem;
                            border: #ffa600 solid 0.124rem;
                            display: inline-block;
                            padding: 2px 6px;
                            font-size: 0.9rem;
                            transition: 0.2s;
                            margin-left: 0.4rem;
                            margin-right: 0.4rem;
                            font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                            color: white;
                            background-color: #ffa600;
                        } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }
            }
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-salvar-tab {
            text-align: center;
            margin: 1rem;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-excluir {
            img {
                cursor: pointer;
                width: 23px;
                padding: 0.2rem;
                height: 1.4rem;
                display: inline-block;
            }

            button {
                border-radius: 1rem;
                border: none;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }

        #botao-salvar-tab:hover {
            background-color: #4CAF50;
        }
    }

    @media screen and (min-width: "60px") and (max-width: "1065px") {
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            border: 2px solid #00a339;

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                    border-radius: 0.5rem;
                    border: none;
                    width: 44.27rem;
                } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: grid;
                margin: 1rem 0;

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                    color: #ffa600;
                    border-radius: 0.5rem;
                    border: #ffa600 solid 0.124rem;
                    display: inline-block;
                    padding: 2px 6px;
                    font-size: 0.9rem;
                    transition: 0.2s;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                    font-family: "Montserrat-Medium";
                    }
                    .upload:hover{
                    color: white;
                    background-color: #ffa600;
                    } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: calc(100vw - 5.5rem);
            }
        }

        .caixa-form-blocos {
            background-color: #efefef;
            padding: 1rem;
            border-radius: 0.2rem;

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                    border-radius: 0.5rem;
                    border: none;
                    width: 44.27rem;
                } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: block;
                margin: 1rem 0;

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                    color: #ffa600;
                    border-radius: 0.5rem;
                    border: #ffa600 solid 0.124rem;
                    display: inline-block;
                    padding: 2px 6px;
                    font-size: 0.9rem;
                    transition: 0.2s;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                    font-family: "Montserrat-Medium";
                    }
                    .upload:hover{
                    color: white;
                    background-color: #ffa600;
                    } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: 100%;
            }
        }

        .blocos-textos {
            margin: 1rem 0rem;
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-salvar-tab {
            text-align: center;
            margin: 1rem;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-excluir {
            img {
                cursor: pointer;
                width: 23px;
                padding: 0.2rem;
                height: 1.4rem;
                display: inline-block;
            }

            button {
                border-radius: 1rem;
                border: none;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }

        #botao-salvar-tab:hover {
            background-color: #4CAF50;
        }
    }
</style>
