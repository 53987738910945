<template>
  <div>
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="topo-header centro">
        <botao-voltar class="voltar" nomeRota="Cessao" />
        <h1>Visualizar Cessão</h1>
      </div>
      <div class="form centro">
        <div class="dados">
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p>Nome do(a) servidor(a):
                  <span>{{ cessao.nome_servidor }}</span>
                </p>
                <p>CPF do(a) servidor(a):
                  <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
                </p>
              </div>
              <p>Cargo do(a) servidor(a):
                <span v-if="cessao.nome_cargo">{{ cessao.nome_cargo }}</span>
                <span v-else>Nenhum cargo encontrado</span>
              </p>
            </div>
          </fieldset>
          <fieldset>
            <div class="dados">
              <div class="flexbox-1">
                <p>Ônus:
                  <span>{{ cessao.onus }}</span>
                </p>
                <p>Status:
                  <span>{{ cessao.status }}</span>
                </p>
              </div>
              <div class="flexbox-1">
                <p>Órgão de origem:
                  <span>{{ cessao.orgao_origem }}</span>
                </p>
                <p>Órgão de destino:
                  <span>{{ cessao.orgao_destino }}</span>
                </p>
              </div>
              <div class="flexbox-1">
                <p>Data de início da cessão:
                  <span>{{ formatarData(cessao.data_inicio) }}</span>
                </p>
                <p v-if="cessao.data_termino !== null">Data de término da cessão:
                  <span>{{ formatarData(cessao.data_termino) }}</span>
                </p>
                <p v-else>Data de término da cessão:
                  <span>Indeterminada</span>
                </p>
              </div>
              <div class="flexbox-1">
                <!--
                <p>Data de entrada do processo:
                  <span>{{ formatarData(cessao.data_entrada_processo) }}</span>
                </p>-->
                <p>Cargo ou FC:
                  <span v-if="cessao.fc">{{ cessao.fc }}</span>
                  <span v-else>Nenhum</span>
                </p>
              </div>
              <div v-if="cessao.retorno == true">
                <p>Retorno:</p>
                <fieldset class="convenio">
                  <div class="flexbox-3">
                    <p>Data de retorno:
                        <span>{{ formatarData(cessao.data_retorno) }}</span>
                    </p>
                    <p>Processo SEI do retorno:
                      <span>{{ cessao.numero_processo_sei_retorno }}</span>
                    </p>           
                  </div>
                  <p v-if="cessao.observacao_retorno !== null && cessao.observacao_retorno !== ''">Observação do retorno:
                    <span>{{ cessao.observacao_retorno }}</span>
                  </p>
                </fieldset>
              </div>
              <div class="flexbox-1">
                <p>Processo SEI:
                  <a :href="retornarLinkAcesso(cessao.link_acesso)" target="_blank" rel="noopener noreferrer">
                    {{ cessao.numero_processo_sei }}
                  </a>
                </p>
              </div>
              <div class="andamentos-sei">
                <p>Andamentos no SEI:</p>
                <table class="tabela-andamentos">
                  <thead>
                    <tr>
                      <th>Data/Hora</th>
                      <th>Unidade</th>
                      <th>Usuário</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="andamentoSei in andamentosPaginados" :key="andamentoSei.id_andamento" class="fieldset-andamentos">
                      <td data-label="Data/Hora:">
                        <span>{{ andamentoSei.data_hora }}</span>
                      </td>
                      <td data-label="Unidade:">
                        <span>{{ andamentoSei.unidade.sigla }}</span>
                      </td>
                      <td data-label="Usuário:">
                        <span>{{ andamentoSei.usuario.sigla }}</span>
                      </td>
                      <td data-label="Descrição:">
                        <span>{{ formatarDescricao(andamentoSei.descricao) }}</span> <!--FORMATAR DESCRIÇÃO-->
                      </td>
                    </tr>
                    <tr v-if="andamentosSei.length === 0" class="sem-resultados">
                      <td colspan="4"><b>Nenhum Resultado Encontrado</b></td>
                    </tr>
                  </tbody>
                </table>
                <paginate
                  :page-count="totalPaginas"
                  :click-handler="paginarAndamentos"
                  :prev-text="'Anterior'"
                  :next-text="'Próximo'"
                  :container-class="'className'"
                  :page-class="'page-item'"
                  :prev-class="'page-item'"
                  :next-class="'page-item'"
                  :disabled-class="'disabled'"
                  :active-class="'active-page'">
                </paginate>
              </div>
              <div v-if="cessao.convenio_id !== null">
                <p>Convênio:</p>
                <fieldset class="convenio">
                  <div v-if="cessao.nome_convenio !== null">
                    <p>Nome do cônvenio:</p>
                    <span>{{ cessao.nome_convenio }}</span>
                  </div>
                  <div class="dados">
                    <p>Nome da faixa:
                      <span v-if="cessao.faixa_convenio">{{ cessao.faixa_convenio }}</span>
                      <span v-else>Sem faixa</span>
                    </p>
                  </div>
                </fieldset>
              </div>
              <div v-if="cessao.observacao !== null && cessao.observacao !== ''">
                <p>Observação:
                  <span>{{ cessao.observacao }}</span>
                </p>
              </div>
            </div>
          </fieldset>
        </div>
        <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
          <div class="div-center">
            <div :id="cessao.id">
              <a :href="'/cessoes/' + cessao.id + '/edit'">
                <button id="botao-editar">
                  Editar
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer
          :createdByLabel="'Criado por'"
          :criadoPor="cessao.criado_por"
          :lastUpdatedByLabel="'Última atualização feita por'"
          :atualizadoPor="cessao.atualizado_por"
          :emLabel="' em '"
          :SistemaLabel="'Sistema'"
          :created_at="cessao.created_at"
          :updated_at="cessao.updated_at"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import CessaoApi from "../../services/CessaoApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"
  import Paginate from 'vuejs-paginate';

  export default {
    name: "ExibirCessao",
    props: {
      cessao: Object,
      temPermissao: Object,
    },
    components: {
      BotaoVoltar,
      Footer,
      Paginate,
    },
    data() {
      return {
        carregando: true,
        r: false,
        andamentosSei: [],
        idProcedimento: null,
        dadosCessao: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        },
        paginaAtual: 1,
        resultadosPorPagina: 5,
      }
    },
    computed: {
      totalPaginas() {
        return Math.ceil(this.andamentosSei.length / this.resultadosPorPagina);
      },
      andamentosPaginados() {
        const inicio = (this.paginaAtual - 1) * this.resultadosPorPagina;
        const fim = inicio + this.resultadosPorPagina;
        return this.andamentosSei.slice(inicio, fim);
      },
    },
    created() {
      this.cessaoApi = new CessaoApi();
      this.consultarProcedimentoSei();
      this.consultarAndamentosSei()
    },
    methods: {
      retornarLinkAcesso(urlSei) {
        return urlSei + this.idProcedimento;
      },
      async consultarProcedimentoSei () {
        try {
          this.carregando = true;

          let numeroProcessoSei = this.cessao.numero_processo_sei
          const procedimentoSei = await this.cessaoApi.getProcedimentoSei(numeroProcessoSei);
          let id_procedimento = procedimentoSei.consultar_procedimento_response.parametros.id_procedimento

          if(id_procedimento) {
            this.idProcedimento = id_procedimento
          } else {
            this.idProcedimento = null;
          }

          this.carregando = false;
        } catch (err) {
          console.log("consultarProcedimentoSei", err);

          this.carregando = false;
        }
      },
      async consultarAndamentosSei () {
        try {
          this.carregando = true;

          let numeroProcessoSei = this.cessao.numero_processo_sei
          const dadosAndamentosSei = await this.cessaoApi.getAndamentosSei(numeroProcessoSei);
          let arrayAndamentosSei = dadosAndamentosSei

          arrayAndamentosSei.listar_andamentos_response.parametros.item.map((andamentoSei) => {
            this.andamentosSei.push(andamentoSei)
          })

          this.carregando = false;
        } catch (err) {
          console.log("consultarAndamentosSei", err);

          this.carregando = false;
        }
      },
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },      
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarCPF(cpf) {
        // Lógica para formatar o CPF (exemplo: 123.456.789-01)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      formatarDescricao(descricao) {
        let stringOriginal = descricao;

        // Verificar se a string contém a tag <a>
        if (stringOriginal.includes('<a')) {
          // Criar um elemento temporário
          let elementoTemporario = document.createElement('div');
          elementoTemporario.innerHTML = stringOriginal;

          // Extrair o texto da tag <a>
          let textoDentroDaTag = elementoTemporario.querySelector('a').textContent;

          // Substituir a tag <a> pela string extraída
          let novaString = stringOriginal.replace(/<a.*?>(.*?)<\/a>/, textoDentroDaTag);

          return novaString;
        } else {
          // Se a string não contém a tag <a>, mantê-la inalterada
          return stringOriginal;
        }
      },
      paginarAndamentos(numeroPagina) {
        this.paginaAtual = numeroPagina;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .disabled-page {
    color: #484344;
    
    a {
      cursor: not-allowed;
    }
  }
  @media screen and (min-width: 1066px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;
          margin: 0rem 0 1.5rem 0;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 1rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .gridbox {
              display: grid;
              gap: .2rem;

              span {
                margin: 0;
              }
            }
            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }
            .flexbox-2 {
              display: grid;
              grid-template-columns: 1.5fr 1fr 1fr;
              gap: 2rem;
            }
            .flexbox-3 {
              display: grid;
              grid-template-columns: .3fr .7fr;
              gap: 2rem;
            }
            .flexbox-4 {
              display: flex;
              gap: 2rem;
            }
            a {
              width: fit-content;
              height: fit-content;
              .icone-sei {
                width: 2rem;
              }
              .icone-sei:hover {
                width: 1.9rem;
              }
              .icone-sei-disabled {
                width: 2rem;
                cursor: not-allowed;
              }
            }
            .andamento-sei {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }

        .andamentos-sei {
          .tabela-andamentos {
            margin: 0 0 1rem 0;
          }

          table {
            border-collapse: collapse;
            border-radius: 1rem;
            width: 100%;
            background-color: #edf2ee;

            thead {
              background-color: #155f9b;
              border-bottom: solid #2c2c2c 1px;

              th {
                padding: .5rem .7rem;
                text-align: center;
                border-right: 1px solid #484344;
                font-size: .9rem;
                font-weight: bold;
                color: #f8f8f8;
              }

              th:first-child {
                border-radius: 1rem 0rem 0rem 0rem;
                border-left: none;
              }

              th:last-child {
                border-radius: 0rem 1rem 0rem 0rem;
                border-right: none;
              }
            }

            tbody {
              tr {
                margin: 1rem;

                td {
                  padding: 1rem .7rem;
                  vertical-align: middle;
                  text-align: center;

                  span {
                    font-size: .9rem;
                  }
                }

                td:nth-child(1) {
                  max-width: 10rem;
                  min-width: 10rem;
                  padding: .9rem .5rem;
                  text-align: center;          
                }

                td:nth-child(2) {
                  max-width: 10rem;
                  min-width: 10rem;
                  text-align: center;
                }

                td:nth-child(3) {
                  text-align: center;
                }

                td:nth-child(4) {
                  text-align: left;
                }

              }

              tr:nth-child(4n + 1),
              tr:nth-child(4n + 2) {
                text-align: center;
              }

              .sem-resultados {
                background-color: transparent !important;
                text-align: center;
                height: 5rem;
                color: rgb(73, 73, 73);
                font-style: oblique;
              }
            }
          }
        }

        td {
          img {
            height: 1.5rem;
            margin: 0rem 0.5rem 0rem 0.5rem;
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: 2rem;
        color: #000000;
        margin: 0 0 0 3rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
    .inline {
      display: inline-block;
    }
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
  }
  @media screen and (min-width: 600px) and (max-width: 1065px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 1rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;
          margin: 0rem 0 1.5rem 0;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: .9rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 1rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: .9rem;
              font-weight: normal;
            }

            .gridbox {
              display: grid;
              gap: .2rem;
              margin: 0;

              span {
                margin: 0;
              }
            }
            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }
            .flexbox-2 {
              display: grid;
              grid-template-columns: 1.5fr 1fr 1fr;
              gap: 2rem;
            }
            .flexbox-3 {
              display: grid;
              grid-template-columns: .3fr .7fr;
              gap: 2rem;
            }
            .flexbox-4 {
              display: grid;
              gap: 1rem;
            }
            .icone-sei {
              width: 2rem;
            }
            .icone-sei:hover {
              width: 1.9rem;
            }
            .andamento-sei {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: .9rem;
                font-weight: normal;
              }
            }
          }
        }

        .andamentos-sei {
          .tabela-andamentos {
            margin: 0 0 1rem 0;
          }

          table {
            border-collapse: collapse;
            border-radius: 1rem;
            width: 100%;
            background-color: #edf2ee;

            thead {
              background-color: #155f9b;
              border-bottom: solid #2c2c2c 1px;

              th {
                padding: .5rem .5rem;
                text-align: center;
                border-right: 1px solid #484344;
                font-size: calc(0.6rem + .5vw);
                font-weight: bold;
                color: #f8f8f8;
              }

              th:first-child {
                border-radius: 1rem 0rem 0rem 0rem;
                border-left: none;
              }

              th:last-child {
                border-radius: 0rem 1rem 0rem 0rem;
                border-right: none;
              }
            }

            tbody {
              tr {
                margin: 1rem;

                td {
                  padding: 1rem .5rem;
                  vertical-align: middle;
                  text-align: center;

                  span {
                    font-size: calc(0.5rem + .5vw);
                  }
                }

                td:nth-child(1) {
                  padding: .9rem .5rem;
                  text-align: center;          
                }

                td:nth-child(2) {
                  text-align: center;
                }

                td:nth-child(3) {
                  text-align: center;
                }

                td:nth-child(4) {
                  text-align: left;
                }

              }

              tr:nth-child(4n + 1),
              tr:nth-child(4n + 2) {
                text-align: center;
              }

              .sem-resultados {
                background-color: transparent !important;
                text-align: center;
                height: 5rem;
                color: rgb(73, 73, 73);
                font-style: oblique;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: fit-content;
        color: #000000;
        padding: 0;
        margin: 3rem 1rem 0 1rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
    .inline {
      display: inline-block;
    }
  }
  @media screen and (min-width: 80px) and (max-width: 599px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 1rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;
          margin: 0rem 0 1.5rem 0;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;

            p {
              font-family: "Montserrat-Medium";
              font-size: .9rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 1rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: .9rem;
              font-weight: normal;
            }

            .gridbox {
              display: grid;
              gap: .2rem;
              margin: 0;

              span {
                margin: 0;
              }
            }
            .flexbox-1 {
              display: grid;
            }
            .flexbox-2 {
              display: grid;
              grid-template-columns: 1.5fr 1fr 1fr;
              gap: 2rem;
            }
            .flexbox-3 {
              display: grid;
              grid-template-columns: .3fr .7fr;
              gap: 2rem;
            }
            .flexbox-4 {
              display: grid;
              gap: 1rem;
            }
            .icone-sei {
              width: 2rem;
            }
            .icone-sei:hover {
              width: 1.9rem;
            }
            .andamento-sei {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;
              p {
                margin: 0 0 1rem 0;
              }
              span {
                margin-left: .3rem;
                font-size: .9rem;
                font-weight: normal;
              }
            }
          }
        }

        .andamentos-sei {
          table {
            width: 100%;

            tbody {
              background-color: transparent;
            }

            thead {
              border: none;
              clip: rect(0 0 0 0);
              height: 1px;
              margin: -1px;
              overflow: hidden;
              padding: 0;
              position: absolute;
              width: 1px;
            }

            tr {
              display: block;
              margin-bottom: 1em;
              background-color: #dbdbdbba;
              border-radius: 2rem;

              td {
                padding: 0rem !important;
                min-height: 1.94rem;

                div {
                  display: inline;
                }
              }

              td:nth-child(2) {
                text-align: left !important;
              }

              td:nth-child(3) {
                text-align: left !important;
              }

              td:nth-child(4) {
                text-align: left !important;
              }

              td:nth-child(5) {
                text-align: left !important;
                max-width: 50rem;
              }

              td::before {
                content: attr(data-label);
                font-weight: bold;
                color: #155f9b;
                margin-right: 0.5rem;
                font-size: .9rem;
              }
            }

            td {
              display: block;
              min-height: 1.3rem;

              span {
                font-size: .85rem;
                margin: 0;
                padding: 0;
              }
            }

            .fieldset-andamentos {
              border: 1px solid;
              border-radius: 1rem;
              box-shadow: #484344 1px 5px 15px;
              width: 100%;
              background-color: #fff;
              font-family: "Poppins";
              text-align: center;
              margin: 0rem auto 2rem auto;
              font-weight: 600 !important;
              font-size: 1rem;
              padding: .7rem .5rem;
            }
          }
        }
      }  
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: fit-content;
        color: #000000;
        padding: 0;
        margin: 3rem 1rem 0 1rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
    .inline {
      display: inline-block;
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: fit-content;
    height: 0px auto;
    margin: 0px auto;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 1.5rem;
    font-family: "Montserrat-Medium";

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1.6rem;
    }

    label {
      font-size: 1.3rem;
      color: rgba(255, 255, 255, 0.556);
      display: inline;
      position: relative;
      top: 0rem;
    }
  }
</style>