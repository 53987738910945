<template>
  <div class="resultados">
    <table>
      <thead>
        <tr>
          <th>CPF</th>
          <th>Servidor(a)</th>
          <th>Status</th>
          <th>Órgão de origem</th>
          <th>Órgão de destino</th>
          <th>Data de início da cessão</th>
          <th>Data de término da cessão</th>
          <!--<th>Data de entrada do processo</th>-->
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="cessao in cessoesFormat" :key="cessao.id" :class="{ 'linha-selecionada': cessaoSelecionada && cessaoSelecionada.id === cessao.id }">
          <td data-label="CPF:" class="topo-card">
            <span>{{ formatarCPF(cessao.cpf_servidor) }}</span>
          </td>
          <td data-label="Nome do(a) servidor(a):" class="topo-card">
            <span>{{ cessao.nome_servidor }}</span>
          </td>
          <td for="selectStatus" data-label="Status:">
            <v-select
              class="vselect" 
              v-model="cessao.status_id" 
              :options="status"
              :reduce="status => status.id" 
              label="nome"
              :clearable="false"
              @input="abrirPopUpConfirmacao(cessao)"
              disabled>
            </v-select>
          </td>
          <td data-label="Órgão de origem:">
            <span>{{ truncateText(cessao.orgao_origem, 30) }}</span>
          </td>
          <td data-label="Órgão de destino:">
            <span>{{ truncateText(cessao.orgao_destino, 30) }}</span>
          </td>
          <td data-label="Data de inicio da cessão:">
            <span>{{ formatarData(cessao.data_inicio) }}</span>
          </td>
          <td data-label="Data de término da cessão:">
            <span v-if="cessao.data_termino !== null">{{ formatarData(cessao.data_termino) }}</span>
            <span v-else>Indeterminada</span>
          </td>
          
          <!--
          <td data-label="Data de entrada do processo:">
            <span>{{ formatarData(cessao.data_entrada_processo) }}</span>
          </td>-->
          <td class="coluna-acoes-tabela">
            <!-- Visualizar -->
            <div class="icone-tabela">
              <router-link :to="{
                name: 'CessaoShow',
                params: { id: cessao.id, renovar: false },
              }">
                <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
              </router-link>
            </div>
          </td>
        </tr>
        <tr v-if="!cessoesFormat || cessoesFormat.length === 0" class="sem-resultados">
          <td colspan="7"><b>Nenhum Resultado Encontrado</b></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>


<script>
import { parseISO, format } from "date-fns";
import vSelect from "vue-select";
import CessaoApi from "../../services/CessaoApi";

export default {
  name: "TabelaResultadosCessoes",
  props: {
    cessoes: Array,
    cessao: Object,
  },
  data() {
    return {
      cessoesFormat: this.cessoes.map((v) => {
        return {
          ...v,
        };
      }), 
      msgErros: {
        status: ''
      },
      cessaoSelecionada: {},
      status: [],
      cessoesData: [],
      TabelaResultadosCessoes: this.cessao
        ? {
          id: this.cessao.id,
          status_id: this.cessao.status_id,
        }
        : {
          id: null,
          status_id: null,
        },
    };
  },
  components: {
    vSelect,      
  },
  created() {
    this.cessaoApi = new CessaoApi();
    this.carregarStatus();
    this.carregarCessoes();
  },
  methods: {
    async carregarCessoes() {
      try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false

          const dadosCessoesData = await this.cessaoApi.getCessoesData();
          this.cessoesData = dadosCessoesData
      } catch (err) {
          console.log("carregarCessoes", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.cessoesData = [];
      }
    },
    async carregarStatus() {
      try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false
          const dadosStatus = await this.cessaoApi.getStatus();
                    this.status = dadosStatus
      } catch (err) {
          console.log("carregarStatus", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.status = [];
      }
    },
    truncateText(text, maxLength) {
      if (text.length <= maxLength) {
        return text;
      } else {
        return text.slice(0, maxLength) + "...";
      }
    },
    formatarData: function (value) {
      if (value == null) {
        return;
      }
      return format(parseISO(value), "dd/MM/yyyy");
    },
    formatarDataParaComparacao(value) {
      let newValue = new Date(value);
      let timeToCompare = newValue.getTime();
      return timeToCompare;
    },
    formatarCPF(cpf) {
      // Lógica para formatar o CPF (exemplo: 123.456.789-01)
      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
  },
};
</script>


<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {

    .resultados {
      width: 100%;
      height: max-content;
      margin: 0;

      table {
        border-collapse: collapse;
        width: 100%;

        a {
          cursor: pointer;
          color: #375a3a;
        }

        thead {
          border-bottom: solid #484344 1px;
          color: #0a4a22;

          th {
            padding: .5rem .7rem;
            text-align: center;
            background-color: #edf2ee;
            border-right: 1px solid #484344;
            font-size: .9rem;
            font-weight: bold;
          }

          th:last-child {
            border-right: none;
          }
        }

        tbody {
          tr {
            margin: 1rem;

            td {
              padding: 1rem .7rem;
              vertical-align: middle;
              font-size: .9rem;
              text-align: center;
            }

            td:nth-child(1) {
              min-width: 9rem;
              padding: .9rem;
              text-align: left;          
            }

            td:nth-child(2) {
              text-align: left;
            }

            td:nth-child(3) {
              text-align: left;
            }

            td:nth-child(4) {
              text-align: left;
            }

            td:nth-child(5) {
              text-align: left;
              max-width: 50rem;
            }
          }

          tr:nth-child(4n + 1),
          tr:nth-child(4n + 2) {
            text-align: center;
          }

          .coluna-acoes-tabela {
            min-width: 10rem;
            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .05rem 0 .05rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            img {
              height: .9rem;
            }

            button {
              border: none;
              background-color: #fff;
            }
          }

          .sem-resultados {
            background-color: transparent !important;
            text-align: center;
            height: 5rem;
            color: rgb(73, 73, 73);
            font-style: oblique;
          }

          .modal-exclusao {
            visibility: hidden;
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            display: table;
            transition: opacity 0.3s ease;

            .modal-mask {
              border-radius: 1rem;
              position: relative;
              z-index: 1000;
              top: 20vh;
              width: calc(40vw - 10rem);
              height: fit-content;
              background-color: #f2f2f2;
              transition: opacity 0.3s ease;
              margin: 0 auto;
            }

            .modal-container {
              height: 100%;
              padding: .7rem;
              position: relative;
              overflow-y: auto;
              overflow-x: auto;
              font-family: "Montserrat-Medium";

              .modal-header {
                height: fit-content;
                width: fit-content;
                margin: .5rem auto .3rem auto;

                h2 {
                  font-size: 1.3rem;
                  font-weight: bold;
                }
              }

              .modal-body {
                border: 1px solid gray;
                border-radius: 1rem;
                text-align: -webkit-center;

                p {
                  font-size: 1rem;
                  font-weight: bold;
                  margin-top: .3rem;
                  margin-bottom: .5rem;
                  margin-left: 1rem;
                  color: #005516;
                }

                .dados {
                  font-weight: bold;
                  font-size: 1rem;
                  text-align-last: left;
                  margin: 0 0 .5rem 1rem;

                  span {
                    font-weight: normal;
                    font-size: .9rem;
                    margin-left: .3rem;
                  }
                }
              }

              .modal-footer {
                margin: .7rem auto 0rem auto;
                place-content: space-evenly;
                font-size: 1rem;

                .btn-confirmar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #005516;
                  color: #f2f2f2;
                }

                .btn-cancelar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #bd0000;
                  color: #f2f2f2;
                }
              }
            }
          }
        }
      }

      td {
        img {
          height: 1.5rem;
          margin: 0rem 0.5rem 0rem 0.5rem;
        }
      }
    }
  }
  .modal-edit {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
}

.modal-header h2 {
    color: green;
}

.dados {
    margin-top: 20px;
}

.dados p {
    margin: 10px 0;
}

.texto-centralizado {
    text-align: left;
}

.modal-footer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}

.btn-confirmar-edicao,
.btn-cancelar-edicao {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-confirmar-edicao {
    background-color: green;
    color: white;
    margin-right: 10px;
}

.btn-cancelar-edicao {
    background-color: red;
    color: white;
}


  .linha-selecionada {
  background-color: yellow;
}

  @media screen and (min-width: "570px") and (max-width: "1065px") {
    .modal-mask {
      min-width: 60vw;
    }

    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }

    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;

          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;

            .icone-tabela {
              margin: 0rem calc(22vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .9rem;
              margin: 0;
            }

            button {
              border: none;
            }
          }
        }

        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }

          td:nth-child(2) {
            text-align: left !important;
          }

          td:nth-child(3) {
            text-align: left !important;
          }

          td:nth-child(4) {
            text-align: left !important;
          }

          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }

        .sem-resultados {
          padding-top: 5rem;
        }

        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }

          span {
            font-size: .9rem;
          }
        }

        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }

        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }

          span {
            font-size: 1rem;
            display: block;
          }
        }

        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 20vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }

          .modal-container {
            height: 100%;
            padding: .7rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.3rem;
                font-weight: bold;
              }
            }

            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .5rem;
                margin-left: 1rem;
                color: #005516;
              }

              .dados {
                font-weight: bold;
                font-size: 1rem;
                text-align: initial;
                margin: 0 0 .5rem 1rem;

                span {
                  font-weight: normal;
                  font-size: .9rem;
                  margin-left: .3rem;
                }
              }
            }

            .modal-footer {
              margin: .7rem auto 0rem auto;
              place-content: space-evenly;
              font-size: 1rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #005516;
                color: #f2f2f2;
              }

              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
        .modal-edit {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 20vh;
            width: calc(40vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }

          .modal-container {
            height: 100%;
            padding: .7rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

          .modal-header {
            height: fit-content;
            width: fit-content;
            margin: .5rem auto .3rem auto;

            h2 {
              font-size: 1.3rem;
              font-weight: bold;
            }
          }

          .modal-body {
            border: 1px solid gray;
            border-radius: 1rem;
            text-align: -webkit-center;

            p {
              font-size: 1rem;
              font-weight: bold;
              margin-top: .3rem;
              margin-bottom: .5rem;
              margin-left: 1rem;
              color: #005516;
            }

            .dados {
              font-weight: bold;
              font-size: 1rem;
              text-align-last: left;
              margin: 0 0 .5rem 1rem;

              span {
                font-weight: normal;
                font-size: .9rem;
                margin-left: .3rem;
              }
            }
          }

          .modal-footer {
            margin: .7rem auto 0rem auto;
            place-content: space-evenly;
            font-size: 1rem;

            .btn-confirmar-exclusao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #005516;
              color: #f2f2f2;
            }

            .btn-cancelar-exclusao {
              border: none;
              border-radius: .5rem;
              width: 30%;
              height: 2.2rem;
              background-color: #bd0000;
              color: #f2f2f2;
            }
          }
        }
      }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "569px") {
    .modal-mask {
      min-width: 70vw;
    }

    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }

    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;

          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;

            .icone-tabela {
              margin: 0rem calc(18vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .6rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .8rem;
              margin: 0;
            }

            button {
              border: none;
            }
          }
        }

        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }

        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }

          td:nth-child(2) {
            text-align: left !important;
          }

          td:nth-child(3) {
            text-align: left !important;
          }

          td:nth-child(4) {
            text-align: left !important;
          }

          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }

        .sem-resultados {
          padding-top: 5rem;
        }

        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }

          span {
            font-size: .9rem;
          }
        }

        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }

        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }

          span {
            font-size: 1rem;
            display: block;
          }
        }

        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 30vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }

          .modal-container {
            height: 100%;
            padding: .3rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.1rem;
                font-weight: bold;
              }
            }

            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .2rem;
                margin-left: 1rem;
                color: #005516;
              }

              .dados {
                font-weight: bold;
                font-size: .9rem;
                text-align: initial;
                margin: 0 0 .5rem .3rem;

                span {
                  font-weight: normal;
                  font-size: .8rem;
                  margin-left: .3rem;
                }
              }
            }

            .modal-footer {
              margin: .5rem auto 0rem auto;
              place-content: space-evenly;
              font-size: .9rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #005516;
                color: #f2f2f2;
              }

              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-container {
    background: #FFF;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease-in-out;
  }

</style>