<template>
  <div class="mensagem">
    <img
      src="../assets/imagens/alert-error.png"
      class="erro-img"
      alt=" alerta de erro "
    />
    <p>
      {{ texto || "Houve um erro ao carregar os resultados." }}
      <br />Por favor, recarregue a página.
    </p>
  </div>
</template>

<script>
  export default {
    name: "ErroResultados",
    props: {
      texto: {
        type: String,
      },
    },
  };
</script>

<style lang="scss" scoped>
.mensagem {
  .erro-img {
    width: 5rem;
  }
  text-align: center;
  margin-bottom: 4rem;
  p {
    color: red;
  }
}
</style>