<template>
  <div>
    <div class="tela-login">
      <div class="right">
        <h1>Link enviado!</h1>
        <form  @submit.prevent="handleSubmit">
            <div class="item">
                <p>
                  Um link foi enviado para o seu email cadastrado. 
                  Abra este link para redefinir sua senha.
                </p>
                <p class="aviso">
                  Após 24h este link irá expirar!
                </p>
            </div>
        </form>
      </div>
      <div class="footer-mobile">
        <img
          title="Logo da Casa Civil e Estado de Goiás"
          src="../../assets/imagens/logo-casa-bco.png"
          class="logo"
          alt="Logo da Casa Civil e Estado de Goiás"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import metaDados from "../../helpers/metaDados";

  export default {
    name: 'TelaAvisoEmail',
    metaInfo() {
      return {
        title: "Movimentação Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e cadastro de cessões/disposições de servidores.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Movimentação Servidores - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 700px) {
    .tela-login {
      display: flex;
      flex-direction: column;
      //background-image: url("../../assets/imagens/bk-login.png");
      //background-attachment: fixed;
      //background-size: cover;
      //background-repeat: no-repeat;
      //background-position: left;
      background-color: #125b19;
      min-height: 100vh;
      justify-content: center;
      align-items: center;
      .left {
        display: block;
        height: 10rem;

        .logo {
          width: 15rem;
          margin: 1rem 0 0;
        }

        .logo-casa {
          display: none;
        }
      }
      .right {
        background-color: white;
        //max-width: 22rem;
        padding: 2rem;
        min-width: 25rem;
        width: 50%;
        height: 20rem;
        border-radius: 4rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

        h1 {
          margin: 0rem 0 2rem 0;
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
        }
        .item {
          display: grid;
          margin: 1rem 0;

          label {
            font-family: "Montserrat-Medium";
            color: #005516;
            margin-bottom: .5rem;
          }
          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }
          input {
            height: 3rem;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: 1.1rem;
          }
          p {
            font-size: calc(1vw + .4rem);
          }
          p.aviso {
            color: red;
            justify-self: center;
          }
        }
        a {
          margin: .5rem 0 0 1rem;
          color: #005516;
          text-align: left;
          font-size: 1.2rem;
          width: fit-content;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .footer-mobile {
        img {
          width: 15rem;
          margin-top: 1rem;
        }
      }
    }
  }
  @media screen and(min-width: 60px) and (max-width: 699px) {
    .tela-login {
      display: flex;
      flex-direction: column;
      //background-image: url("../../assets/imagens/bk-login.png");
      //background-attachment: fixed;
      //background-size: cover;
      //background-repeat: no-repeat;
      //background-position: left;
      background-color: #125b19;
      min-height: 100vh;
      justify-content: center;
      align-items: center;

      .left {
        display: block;
        height: 10rem;

        .logo {
          width: 15rem;
          margin: 1rem 0 0;
        }
        .logo-casa {
          display: none;
        }
      }

      .right {
        background-color: white;
        //max-width: 22rem;
        padding: 2rem;
        min-width: 25rem;
        width: 50%;
        height: 20rem;
        border-radius: 4rem;
        box-shadow: 0 0 1rem rgba(0, 0, 0, 0.61);

        h1 {
          margin: 0rem 0 2rem 0;
          font-family: 'Montserrat-Bolder';
          color: #00a339;
          text-align: center;
        }
        .item {
          display: grid;
          margin: 1rem 0;
          label {
            font-family: "Montserrat-Medium";
            color: #005516;
            margin-bottom: .5rem;
          }
          input:focus {
            outline: none;
            box-shadow: rgba(0, 0, 0, 0.404) 0 0 .3rem;
          }
          input {
            height: 3rem;
            padding-left: .5rem;
            border: silver .1rem solid;
            border-radius: 3rem;
            font-family: 'Montserrat';
            font-size: 1.1rem;
          }
          p.aviso {
            color: red;
            justify-self: center;
          }
        }
        a {
          margin: .5rem 0 0 1rem;
          color: #005516;
          text-align: left;
          font-size: 1.2rem;
          width: fit-content;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .footer-mobile {
        img {
          width: 15rem;
          margin-top: 1rem;
        }
      }
    }
  }
</style>