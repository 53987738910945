<template>
  <div class="menu-fixed">
    <div id="error" class="error">
      <button @click.prevent="fecharTelaMensagens()">
        <img src="../assets/imagens/icon-close.png" alt="Botão de fechar" class="botao-fechar">
      </button>
      <h2>Não foi possível salvar, pois:</h2>
      <ul>
        <li v-if="mensagensModal.servidor_cpf !== '' && mensagensModal.servidor_cpf !== undefined">
          <div v-if="typeof mensagensModal.servidor_cpf == 'object'">
            {{ mensagensModal.servidor_cpf[0] }}
          </div>
          <div v-else>
            {{ mensagensModal.servidor_cpf }}
          </div>
        </li>
        <li v-if="mensagensModal.servidor_nome !== '' && mensagensModal.servidor_nome !== undefined">
          <div v-if="typeof mensagensModal.servidor_nome == 'object'">
            {{ mensagensModal.servidor_nome[0] }}
          </div>
          <div v-else>
            {{ mensagensModal.servidor_nome }}
          </div>
        </li>
        <li v-if="mensagensModal.servidor_cargo !== '' && mensagensModal.servidor_cargo !== undefined">
          <div v-if="typeof mensagensModal.servidor_cargo == 'object'">
            {{ mensagensModal.servidor_cargo[0] }}
          </div>
          <div v-else>
            {{ mensagensModal.servidor_cargo }}
          </div>
        </li>
        <li v-if="mensagensModal.default !== '' && mensagensModal.default !== undefined">
          <div v-if="typeof mensagensModal.default == 'object'">
            {{ mensagensModal.default[0] }}
          </div>
          <div v-else>
            {{ mensagensModal.default }}
          </div>
        </li>
        <li v-if="mensagensModal !== '' && mensagensModal !== undefined && typeof mensagensModal == 'string'">
          <div>
            {{ mensagensModal }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "MsgErroFormularios",
    props: {
      mensagensModal: {
        type: [Object, String],
        required: true
      },
      erroModal: {
        type: Boolean,
        required: true,
      }
    },
    methods: {
      fecharTelaMensagens() {
        this.$emit("fechar-erro");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .menu-fixed {
    width: 100%;
    .error {
      background-color: #f71216e7;
      border-radius: 7px;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-bottom: 0.9rem;
      text-align: center;

      button {
        float: right;
        margin: .3rem .3rem 0 0;
        border: none;
        background-color: transparent;
        .botao-fechar {
          width: 1rem;
        }
      }

      button:hover {
        margin: .4rem .4rem 0 0;
        .botao-fechar {
          width: .8rem;
        }
      }

      h2 {
        display: block;
        font-size: 1.4rem;
        padding-left: 1rem;
        margin-block-start: 0.83rem;
        margin-block-end: 00.83rem;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
        font-family: "Montserrat-Medium";
        cursor: default;
        color: #000;
      }

      ul {
        display: block;
        list-style-type: disc;

        li {
          font-size: 1rem;
          display: list-item;
          text-align: left;
          color: black;
          background-color: #f7121609;
          font-family: "Montserrat-Medium";
        }
      }
    }
  }

  /* .error{
  background-color: #F39999;
  border-radius: 7px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-bottom: 0.9rem;
  h2{
    display: block;
    font-size: 1.5rem;
    margin-block-start: 0.83rem;
    margin-block-end: 00.83rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
    font-family: "Montserrat-Medium";
  }
  ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1rem;
    margin-block-end: 1rem;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    li{
      display: list-item;
      text-align: left;
      color: black;
      background-color: #F39999;
      font-family: "Montserrat-Medium";
    }
  }
} */
</style>