<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
        <div class="topo-index">
          <h1>Servidores</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'ServidorNew',
          }">
            <span>+</span> Novo(a) Servidor(a)
          </router-link>
        </div>
        <div class="caixa-pesquisa"> <!-- MUDAR ITENS DA PESQUISA -->
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div class = "row2-data">
                <div class="item">
                  <label> CPF: </label>
                  <the-mask :mask="['###.###.###-##']" v-model='filtro.cpf' placeholder="Digite o CPF" />
                </div>
                <div class="item">
                  <label> Nome: </label>
                  <input type="text" placeholder="Digite o nome" v-model="filtro.nome" />
                </div>
                <div class="item">
                  <label> Cargo: </label>
                  <!--<input type="text" placeholder="Digite o cargo" v-model="filtro.cargo" />-->
                  <v-select
                    class="vselect"
                    v-model="filtro.cargo"  
                    id="selectedCargo" 
                    :options="paginated"
                    label="nome" 
                    placeholder="Digite o nome do cargo"
                    :filterable="false" 
                    @search="onSearch"
                    @input="onCargoSelect">
                    <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                    </li>
                  </v-select>
                </div>      
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div v-if="temPermissao.admin || temPermissao.gpd" class="container-tabela">
          <TabelaResultadosServidores :servidores="servidores" :servidor="servidor"
            v-if="!carregando && !erro && !erroFiltro">
          </TabelaResultadosServidores>
        </div>
        <div v-else class="container-tabela">
          <TabelaResultadosServidoresUsuario :servidores="servidores" :servidor="servidor"
            v-if="!carregando && !erro && !erroFiltro">
          </TabelaResultadosServidoresUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
            :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
            active-class="active-page"></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import Auth from '../../services/Auth';

  import TabelaResultadosServidores from "../../components/servidores/TabelaResultadosServidores.vue";
  import ServidorApi from "../../services/ServidorApi";
  import MsgErroFormularios from "../../components/MsgErroFormularios.vue";
  import TabelaResultadosServidoresUsuario from "../../components/servidores/TabelaResultadosServidoresUsuario.vue";

  export default {
    name: "Servidor",
    metaInfo() {
      return {
        title: "Servidores",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todos os servidores realizados.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todos os servidores realizados.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Servidores - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        temPermissao: null,
        servidores: [],
        servidor: null,
        carregando: true,
        msgErros: {
          servidor: '',
        },
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        filtro: {
          cpf: null,
          nome: null,
          cargo: null,
        },
        search: '',
        offset: 0,
        limit: 10,
        cargos: [],
        selectedCargo: [],
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      MsgErroFormularios,
      TabelaResultadosServidores,
      TabelaResultadosServidoresUsuario,
      Paginate,
      vSelect,
    },
    created() {
      this.servidorApi = new ServidorApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarServidor();
      this.carregarCargos();
      this.temPermissao = auth.temPermissao;
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.cpf != from.query.cpf ||
          to.query.nome != from.query.nome ||
          to.query.cargo != from.query.cargo
        if (
          from.name == "Servidor" &&
          to.name == "Servidor" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarServidor();
        }
      },
    },
    methods: {
      onSearch(query) {
        this.search = query
        this.offset = 0
      },
      onCargoSelect(selectedCargo) {
        this.filtro.cargo = selectedCargo.nome;
      },
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      fazerBusca() {
        this.$router.push({
          name: "Servidor",
          query: {
            ...this.filtro,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;

        const filtro = {
          cpf: this.getParam("cpf"),
          nome: this.getParam("nome"),
          cargo: this.getParam("cargo"),
        };

        this.$router.push({
          name: "Servidor",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      async carregarCargos() {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false

          const dadosCargos = await this.servidorApi.getCargos();
          this.cargos = dadosCargos
        } catch (err) {
          console.log("carregarCargos", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.cargos = [];
        }
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false;
          /* this.carregandoFiltro = false; */

        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
        }
      },
      carregarServidor: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina =
            this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.filtro = {
            cpf: this.getParam("cpf"),
            nome: this.getParam("nome"),
            cargo: this.getParam("cargo"),
          };

          if (this.servidorApi.cancelTokens.getServidores) {
            this.servidorApi.cancelTokens.getServidores.cancel();
          }

          let data = await this.servidorApi.getServidores({
            ...this.filtro,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });

          this.servidores = data.resultados;
          this.totalResultados = data.total_resultados;
          if (data.total_resultados >= 1000) {
            this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          } else {
            this.totalResultadosTexto = data.total_resultados;
          }
          this.carregando = false;
        } catch (err) {
          if (this.servidorApi.isCancel(err)) {
            return;
          }
          console.log("carregarServidor", err);
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          cpf: undefined,
          nome: undefined,
          cargo: undefined,
        };
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_servidor",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_servidor");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
      filtered() {
        return this.cargos.filter((cargo) =>
          cargo.nome.toLowerCase().includes(this.search.toLowerCase())
        )
      },
      paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset)
      },
      hasNextPage() {
        const nextOffset = this.offset + this.limit
        return Boolean(
          this.filtered.slice(nextOffset, this.limit + nextOffset).length
        )
      },
      hasPrevPage() {
        const prevOffset = this.offset - this.limit
        return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
        )
      },
    },
  };
</script>

<style lang="scss" scoped>
/* Remove as setas de aumento e diminuição do input tipo "number" */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (min-width: "1066px") {
  .div-conteudo {
    width: 100vw;

    .centro {
      max-width: 96vw;
      margin: 0 auto;
    }

    .index {
      .topo-index {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          font-size: 1.8rem;
        }

        a {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          border: #005516 solid 0.15rem;
          padding: 0.3rem 2rem;
          border-radius: 0.2rem;
          transition: 0.2s;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        a:hover {
          color: white;
          background-color: #005516;
        }
      }

      .caixa-pesquisa {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem 1rem 0 0;

        .row4 {
          display: grid;
          grid-template-columns: .25fr .25fr .25fr .25fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }

        .row2 {
          display: grid;
          grid-template-columns: .5fr .5fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }

        .row2-data {
          display: grid;
          grid-template-columns: .5fr .5fr .5fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }

        input {
          border-radius: 0.5rem;
          border: solid 0.1rem;
        }

        h2 {
          margin: 0;
          text-transform: uppercase;
          color: #005516;
          font-size: 1.2rem;
          font-family: "Montserrat-Bold";
          cursor: pointer;
        }

        .item {
          display: grid;
          margin: .5rem 0 .5rem 0;

          label {
            width: fit-content;
            margin-bottom: 0.3rem;
            color: black;
            font-family: "Montserrat-Medium";
            font-size: 0.9rem;
            font-weight: 500;
          }

          input {
            height: 2rem;
            font-family: "Montserrat-Medium";
            padding-left: 0.5rem;
            font-size: 0.9rem;
          }

          select {
            height: 2rem;
            border-radius: 0.5rem;
            border: solid 0.1rem;
            background-color: white;
            font-family: "Montserrat-Medium";
            font-size: 0.84rem;
          }
        }

        .botoes-pesquisa {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;

          .bt-pesquisa {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #005516;
              color: white;
              border: #005516 solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }

          .bt-limpar {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #FFFFFF;
              color: #000;
              border: white solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1.5rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .n-div {
        display: flex;
        justify-content: space-between;

        .nresultados {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.9rem;
            font-family: "Montserrat-Bold";
            margin-top: 0.5rem;
          }
        }

        .npagina {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.9rem;
            font-family: "Montserrat-Medium";
            margin: 0.5rem .5rem 0 0;
          }

          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
            cursor: pointer;
          }
        }
      }

      .container-tabela {
        margin-top: .5rem;
        border-radius: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .resultados {
          width: 100%;
          height: max-content;
          margin: 0;
        }
      }
    }
  }
}

@media screen and (min-width: "60px") and (max-width: "1065px") {
  .div-conteudo {
    width: 100vw;

    .centro {
      width: 90vw;
      margin: 0 auto;
    }

    .index {
      .topo-index {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          font-size: 1.8rem;
        }

        a {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          border: #005516 solid 0.15rem;
          padding: 0.3rem 2rem;
          border-radius: 0.2rem;
          transition: 0.2s;
          margin-bottom: 1.5rem;
          margin-top: 1.5rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        a:hover {
          color: white;
          background-color: #005516;
        }
      }

      .caixa-pesquisa {
        background-color: #dbdbdb;
        padding: 1rem;
        border-radius: 1rem 1rem 0 0;

        .row4-data {
          display: flex;
          justify-content: space-between;
          gap: 1.5rem;

          .item {
            width: -webkit-fill-available;
          }
        }

        input {
          border-radius: 0.5rem;
          border: solid 0.1rem;
        }

        h2 {
          margin: 0;
          text-transform: uppercase;
          color: #005516;
          font-size: 1.2rem;
          font-family: "Montserrat-Bold";
          cursor: pointer;
        }

        .item {
          display: grid;
          margin: .5rem 0 .5rem 0;

          label {
            width: fit-content;
            margin-bottom: 0.3rem;
            color: black;
            font-family: "Montserrat-Medium";
            font-size: 0.9rem;
            font-weight: 500;
          }

          input {
            height: 2rem;
            font-family: "Montserrat-Medium";
            padding-left: 0.5rem;
            font-size: 0.9rem;
          }

          select {
            height: 2rem;
            border-radius: 0.5rem;
            border: solid 0.1rem;
            background-color: white;
            font-family: "Montserrat-Medium";
            font-size: 0.84rem;
          }
        }

        .botoes-pesquisa {
          display: grid;
          gap: 1rem;
          place-content: center;
          margin-top: 1rem;

          .bt-pesquisa {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #005516;
              color: white;
              border: #005516 solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }

          .bt-limpar {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #FFFFFF;
              color: #000;
              border: white solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1.5rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .n-div {
        display: flex;
        justify-content: space-between;

        .nresultados {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.8rem;
            font-family: "Montserrat-Bold";
            margin-top: 0.5rem;
          }
        }

        .npagina {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;
          text-align: end;

          label {
            color: #005516;
            font-size: 0.8rem;
            font-family: "Montserrat-Medium";
            margin: 0.5rem .5rem 0 0;
          }

          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
            cursor: pointer;
          }
        }
      }

      .container-tabela {
        margin-top: .5rem;
        border-radius: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .resultados {
          width: 100%;
          height: max-content;
          margin: 0;
        }
      }
    }
  }
}

.modal-mask {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: fit-content;
  height: 0px auto;
  margin: 0px auto;
  position: relative;
  overflow-y: auto;
  overflow-x: auto;
  font-size: 1.5rem;
  font-family: "Montserrat-Medium";

  img {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
  }

  label {
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.556);
    display: inline;
    position: relative;
    top: 0rem;
  }
}</style>