<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
        <div class="topo-index">
          <h1>Ônus</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'OnusNew',
          }">
            <span>+</span> Novo Ônus
          </router-link>
        </div>
        <div class="caixa-pesquisa">
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div>
                <div class="row2-data">
                  <div class="item">
                    <label for="selectOrgaoOrigem">Nome:</label>
                    <input type="text" placeholder="Digite o nome" v-model="filtro.nome" />
                  </div>
                  <div class="item">
                    <label class="center" for="selectOrgaoDestino">Ativo:</label>
                    <div class="radio-container">
                        <label class="radio-label">
                            <input type="radio" v-model="filtro.ativo" name="choice" :value="true" class="radio-input">
                            Sim
                        </label>
                        <label class="radio-label">
                            <input type="radio" v-model="filtro.ativo" name="choice" :value="false" class="radio-input">
                            Não
                        </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div class="container-tabela" v-if="temPermissao.admin || temPermissao.gpd">
          <TabelaResultadosOnus :onuses="onuses" :onus="onus"
            v-if="!carregando && !erro && !erroFiltro">
          </TabelaResultadosOnus>
        </div>
        <div class="container-tabela" v-else>
          <TabelaResultadosOnusUsuario :onuses="onuses" :onus="onus"
            v-if="!carregando && !erro && !erroFiltro">
          </TabelaResultadosOnusUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
            :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
            active-class="active-page"></paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import Auth from '../../services/Auth';

  import OnusApi from "../../services/OnusApi";
  import MsgErroFormularios from "../../components/MsgErroFormularios.vue";
  import TabelaResultadosOnus from "../../components/onus/TabelaResultadosOnus.vue";
  import TabelaResultadosOnusUsuario from "../../components/onus/TabelaResultadosOnusUsuario.vue";

  export default {
    name: "Onus",
    metaInfo() {
      return {
        title: "Ônus",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todos os ônus realizados.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todos os ônus realizados.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Ônus - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        temPermissao: null,
        onuses: [],
        onus: null,
        carregando: true,
        msgErros: {
          onus: '',
        },
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        filtro: {
          nome: null,
          ativo: null,
        },
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      MsgErroFormularios,
      Paginate,
      TabelaResultadosOnus,
      TabelaResultadosOnusUsuario
    },
    created() {
      this.onusApi = new OnusApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarOnus();
      this.temPermissao = auth.temPermissao;
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.ativo != from.query.ativo ||
          to.query.nome != from.query.nome;
        if (
          from.name == "Onus" &&
          to.name == "Onus" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarOnus();
        }
      },
    },
    methods: {
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      fazerBusca() {
        this.$router.push({
          name: "Onus",
          query: {
            ...this.filtro,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;

        const filtro = {
          nome: this.getParam("nome"),
          ativo: this.getParam("ativo")
        };

        this.$router.push({
          name: "Onus",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false;
          /* this.carregandoFiltro = false; */

        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
        }
      },
      carregarOnus: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina =
            this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.filtro = {
            nome: this.getParam("nome"),
            ativo: this.getParam("ativo")
          };

          if (this.onusApi.cancelTokens.getOnus) {
            this.onusApi.cancelTokens.getOnus.cancel();
          }

          let data = await this.onusApi.getOnuses({
            ...this.filtro,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });

          this.onuses = data.resultados;
          this.totalResultados = data.total_resultados;
          if (data.total_resultados >= 1000) {
            this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          } else {
            this.totalResultadosTexto = data.total_resultados;
          }
          this.carregando = false;
        } catch (err) {
          if (this.onusApi.isCancel(err)) {
            return;
          }
          console.log("carregarOnus", err);
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          ativo: undefined,
          nome: undefined,
        };
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_onus",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_onus");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .center {
    align-self: center;
  }
  .radio-container {
    display: flex;
    gap: 1rem;
  }
  .radio-label {
    display: flex;
    gap: .5rem;
    align-items: center;
  }
  @media screen and (min-width: "1066px") {
    .div-conteudo {
      width: 100vw;
      .centro {
        max-width: 96vw;
        margin: 0 auto;
      }
      .index {
        .topo-index {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            font-size: 1.8rem;
          }
          a {
            color: #005516;
            text-decoration: none;
            font-family: "Montserrat-Medium";
            border: #005516 solid 0.15rem;
            padding: 0.3rem 2rem;
            border-radius: 0.2rem;
            transition: 0.2s;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            span {
              font-family: "Montserrat-Bolder";
            }
          }
          a:hover {
            color: white;
            background-color: #005516;
          }
        }
        .caixa-pesquisa {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem 1rem 0 0;
          .row4-data {
            display: flex;
            justify-content: space-between;
            gap: 1.5rem;
            .item {
              width: -webkit-fill-available;
            }
          }
        .row2-data {
          display: grid;
          grid-template-columns: .5fr .5fr;
          gap: 1rem;

          .item {
            width: -webkit-fill-available;

            .mx-datepicker {
              border: none;
            }
          }
        }
          .flex {
            display: inline-flex;
            gap: 1rem;
            margin: 1rem 0 0 0;
          }
          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }
          h2 {
            margin: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
          }
          .item {
            display: grid;
            margin: .5rem 0 .5rem 0;
            label {
              width: fit-content;
              margin-bottom: 0.3rem;
              color: black;
              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
              font-weight: 500;
            }
            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }
            select {
              height: 2rem;
              border-radius: 0.5rem;
              border: solid 0.1rem;
              background-color: white;
              font-family: "Montserrat-Medium";
              font-size: 0.84rem;
            }
            .status {
              select {
                width: 100%;
              }
            }
          }
          .botoes-pesquisa {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;

            .bt-pesquisa {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #005516;
                color: white;
                border: #005516 solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }

            .bt-limpar {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #FFFFFF;
                color: #000;
                border: white solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1.5rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .n-div {
          display: flex;
          justify-content: space-between;
          .nresultados {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;
            label {
              color: #005516;
              font-size: 0.9rem;
              font-family: "Montserrat-Bold";
              margin-top: 0.5rem;
            }
          }
          .npagina {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;
            label {
              color: #005516;
              font-size: 0.9rem;
              font-family: "Montserrat-Medium";
              margin: 0.5rem .5rem 0 0;
            }
            select {
              border: none;
              background-color: #edf2ee;
              font-size: 0.9rem;
              margin-top: 0.5rem;
              cursor: pointer;
            }
          }
        }
        .container-tabela {
          margin-top: .5rem;
          border-radius: 1.5rem;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          .resultados {
            width: 100%;
            height: max-content;
            margin: 0;
          }
        }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "1065px") {
    .div-conteudo {
      width: 100vw;
      .centro {
        width: 90vw;
        margin: 0 auto;
      }
      .index {
        .topo-index {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            font-size: 1.8rem;
          }
          a {
            color: #005516;
            text-decoration: none;
            font-family: "Montserrat-Medium";
            border: #005516 solid 0.15rem;
            padding: 0.3rem 2rem;
            border-radius: 0.2rem;
            transition: 0.2s;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            span {
              font-family: "Montserrat-Bolder";
            }
          }
          a:hover {
            color: white;
            background-color: #005516;
          }
        }
        .caixa-pesquisa {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem 1rem 0 0;
          .row4-data {
            display: flex;
            justify-content: space-between;
            gap: 1.5rem;
            .item {
              width: -webkit-fill-available;
            }
          }  
          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }
          h2 {
            margin: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
          }
          .item {
            display: grid;
            margin: .5rem 0 .5rem 0;
            label {
              width: fit-content;
              margin-bottom: 0.3rem;
              color: black;
              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
              font-weight: 500;
            }
            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }
            select {
              height: 2rem;
              border-radius: 0.5rem;
              border: solid 0.1rem;
              background-color: white;
              font-family: "Montserrat-Medium";
              font-size: 0.84rem;
            }
            .status {
              select {
                width: 100%;
              }
            }
          }
          .botoes-pesquisa {
            display: flex;
            justify-content: space-between;
            margin-top: .5rem;
            .bt-pesquisa {
              text-align: center;
              button {
                height: 2.5rem;
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;
                img {
                  height: 1rem;
                  margin-right: 0.5rem;
                  margin-bottom: .2rem;
                }
              }
            }
          }

          .bt-limpar {
            text-align: center;

            button {
              height: 2.5rem;
              background-color: #FFFFFF;
              color: #000;
              border: white solid 0.15rem;
              font-size: 1rem;
              padding: 0.3rem 2rem;
              border-radius: 2rem;

              img {
                height: 1.5rem;
                margin-right: 0.5rem;
              }
            }

            button:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .n-div {
        display: flex;
        justify-content: space-between;

        .nresultados {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;

          label {
            color: #005516;
            font-size: 0.8rem;
            font-family: "Montserrat-Bold";
            margin-top: 0.5rem;
          }
        }

        .npagina {
          margin: 0.5rem 0.5rem 0.5rem 0.5rem;
          text-align: end;

          label {
            color: #005516;
            font-size: 0.8rem;
            font-family: "Montserrat-Medium";
            margin: 0.5rem .5rem 0 0;
          }

          select {
            border: none;
            background-color: #edf2ee;
            font-size: 0.9rem;
            margin-top: 0.5rem;
            cursor: pointer;
          }
        }
      }

      .container-tabela {
        margin-top: .5rem;
        border-radius: 1.5rem;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        .resultados {
          width: 100%;
          height: max-content;
          margin: 0;
        }
      }
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    width: fit-content;
    height: 0px auto;
    margin: 0px auto;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 1.5rem;
    font-family: "Montserrat-Medium";

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1.6rem;
    }

      label {
        font-size: 1.3rem;
        color: rgba(255, 255, 255, 0.556);
        display: inline;
        position: relative;
        top: 0rem;
      }
  }
</style>