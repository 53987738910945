<template>
    <div>
        <OrgaoForm :orgao="null" />
    </div>
</template>

<script>
    import OrgaoForm from "../../components/orgaos/OrgaoForm.vue"

    export default {
        name: "OrgaoNew",
        data() {
            return {};
        },
        components: {
            OrgaoForm,
        },
    };
</script>

<style lang="scss" scoped></style>