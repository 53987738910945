<template>
    <div class="div-conteudo">
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div class="fora" v-else>
            <div class="index centro">
                <div class="topo-index">
                    <h1>Home</h1>
                </div>
                <div class="">
                    <div class="cessoes">
                        <h2>Cessões com encerramento iminente</h2>
                        <div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes30Dias" 
                                    :dias="30" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes60Dias" 
                                    :dias="60" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                            <div class="container-tabela">
                                <TabelaResultadosHomeCessoes v-if="!carregando && !erro" 
                                    :cessoes="cessoes90Dias" 
                                    :dias="90" 
                                    :temPermissao="temPermissao">
                                </TabelaResultadosHomeCessoes>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import metaDados from "../../helpers/metaDados";
    import TabelaResultadosHomeCessoes from "../../components/home/TabelaResultadosHomeCessoes.vue";
    import HomeApi from "../../services/HomeApi";
    import CessaoApi from "../../services/CessaoApi";
    import Auth from '../../services/Auth';

    export default {
        name: "Home",
        metaInfo() {
            return {
                title: "Home",
                titleTemplate: "%s - Casa Civil do Estado de Goiás",
                meta: [
                    {
                        vmid: "og:title",
                        property: "og:title",
                        content: "Home - Casa Civil do Estado de Goiás",
                    },
                    ...metaDados(this.$route),
                ],
            };
        },
        data() {
            return {
                temPermissao: null,
                status: [],
                cessoes: [],
                cessoes30Dias: [],
                cessoes60Dias: [],
                cessoes90Dias: [],
                carregando: true,
                erro: false,
            };
        },
        components: {
            TabelaResultadosHomeCessoes,
        },
        created() {
            this.homeApi = new HomeApi();
            this.cessaoApi = new CessaoApi();
            let auth = new Auth();
            this.carregarCessoes();
            this.carregarStatus();
            this.temPermissao = auth.temPermissao;
        },
        methods: {
            carregarCessoes: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.homeApi.cancelTokens.getCessoes) {
                        this.homeApi.cancelTokens.getCessoes.cancel();
                    }

                    const dataCessoes = await this.homeApi.getCessoes();

                    this.cessoes = dataCessoes.resultados;

                    this.carregarCessoesParaCadaTabela();

                    this.carregando = false;
                } catch (err) {
                    if (this.homeApi.isCancel(err)) {
                        return;
                    }
                    this.carregando = false;
                    this.erro = true;
                }
            },
            async carregarStatus() {
                try {
                    if (this.carregandoFiltro && !this.erroFiltro) return;

                    this.erroFiltro = false
                    const dadosStatus = await this.cessaoApi.getStatus();
                    this.status = dadosStatus
                } catch (err) {
                    console.log("carregarStatus", err);
                    this.carregandoFiltro = false;
                    this.erroFiltro = true;
                    this.status = [];
                }
            },
            calcularDiasRestantes(elemento) {
                if (elemento.data_termino !== null) {
                    // Convertendo a Data de término da cessão para um objeto Date
                    const dataTerminoObj = new Date(elemento.data_termino);

                    // Obtendo a data atual
                    const dataAtual = new Date();

                    // Calculando a diferença em milissegundos entre as duas datas
                    const diferencaEmMilissegundos = dataTerminoObj - dataAtual;

                    // Convertendo a diferença de milissegundos para dias
                    const diasRestantes = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

                    return diasRestantes;
                }
            },
            carregarCessoesParaCadaTabela () {
                let idStatusEncerrado;
                this.status.map((elemento) => {
                    if(elemento.codigo == 5) {
                        idStatusEncerrado = elemento.id
                    }
                })

                try {                   
                    this.cessoes.map((cessao) => {
                        if(cessao.status_id !== idStatusEncerrado) {
                            if(this.calcularDiasRestantes(cessao) <= 90 && this.calcularDiasRestantes(cessao) > 60) {
                                this.cessoes90Dias.push(cessao);
                            }
                            if (this.calcularDiasRestantes(cessao) <= 60 && this.calcularDiasRestantes(cessao) > 30) {
                                this.cessoes60Dias.push(cessao);
                            }
                            if (this.calcularDiasRestantes(cessao) <= 30 && this.calcularDiasRestantes(cessao) > 0) {
                                this.cessoes30Dias.push(cessao);
                            }
                        }
                    })
                } catch (err) {
                    if (this.homeApi.isCancel(err)) {
                        return;
                    }
                    this.carregando = false;
                    this.erro = true;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @media screen and (min-width: "1066px") {
        .div-conteudo {
            width: 100vw;
            margin-bottom: 5rem;
            .centro {
                max-width: 96vw;
                margin: 0 auto;
            }
            .index {
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
                .topo-index {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;

                    h1 {
                        font-family: "Montserrat-Bold";
                        color: #ffa600;
                        font-size: 1.8rem;
                    }
                }
                .grid {
                    display: grid;
                    grid-template: "a b";
                    gap: 1rem;
                }
                .cessoes {
                    width: 100%;
                    margin: 0rem auto;
                    .container-tabela {
                        margin: 1rem auto;
                        border-radius: 1.5rem;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                    }
                }
                /*.disposicoes {
                    width: fit-content;
                    .container-tabela {
                        margin: 1rem auto;
                        border-radius: 1.5rem;
                        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
                        width: 46vw;
                    }
                }*/
            }
        }
    }
    @media screen and (min-width: "60px") and (max-width: "1065px") {
        .div-conteudo {
            width: 100vw;

            .centro {
                width: 90vw;
                margin: 0 auto;
            }

            .index {
                .cessoes {
                    width: 100%;
                    margin: 0rem auto;
                }
                .disposicoes {
                    width: fit-content;
                    margin: 2rem 0 1rem 0;
                }
                h2 {
                    font-family: "Montserrat-Bold";
                    color: #005516;
                    font-size: 1.3rem;
                    text-align: center;
                }
                .topo-index {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 1.5rem;
                    margin-top: 1.5rem;

                    h1 {
                        font-family: "Montserrat-Bold";
                        color: #ffa600;
                        font-size: 1.8rem;
                    }

                    a {
                        color: #005516;
                        text-decoration: none;
                        font-family: "Montserrat-Medium";
                        border: #005516 solid 0.15rem;
                        padding: 0.3rem 2rem;
                        border-radius: 0.2rem;
                        transition: 0.2s;
                        margin-bottom: 1.5rem;
                        margin-top: 1.5rem;

                        span {
                            font-family: "Montserrat-Bolder";
                        }
                    }

                    a:hover {
                        color: white;
                        background-color: #005516;
                    }
                }

                .caixa-pesquisa {
                    background-color: #dbdbdb;
                    padding: 1rem;
                    border-radius: 1rem 1rem 0 0;

                    .row4-data {
                        display: flex;
                        justify-content: space-between;
                        gap: 1.5rem;

                        .item {
                            width: -webkit-fill-available;
                        }
                    }

                    input {
                        border-radius: 0.5rem;
                        border: solid 0.1rem;
                    }

                    h2 {
                        margin: 0;
                        text-transform: uppercase;
                        color: #005516;
                        font-size: 1.2rem;
                        font-family: "Montserrat-Bold";
                        cursor: pointer;
                    }

                    .item {
                        display: grid;
                        margin: .5rem 0 .5rem 0;

                        label {
                            width: fit-content;
                            margin-bottom: 0.3rem;
                            color: black;
                            font-family: "Montserrat-Medium";
                            font-size: 0.9rem;
                            font-weight: 500;
                        }

                        input {
                            height: 2rem;
                            font-family: "Montserrat-Medium";
                            padding-left: 0.5rem;
                            font-size: 0.9rem;
                        }

                        select {
                            height: 2rem;
                            border-radius: 0.5rem;
                            border: solid 0.1rem;
                            background-color: white;
                            font-family: "Montserrat-Medium";
                            font-size: 0.84rem;
                        }
                    }

                    .botoes-pesquisa {
                        display: grid;
                        gap: 1rem;
                        place-content: center;
                        margin-top: 1rem;

                        .bt-pesquisa {
                            text-align: center;

                            button {
                                height: 2.5rem;
                                background-color: #005516;
                                color: white;
                                border: #005516 solid 0.15rem;
                                font-size: 1rem;
                                padding: 0.3rem 2rem;
                                border-radius: 2rem;

                                img {
                                    height: 1rem;
                                    margin-right: 0.5rem;
                                }
                            }

                            button:hover {
                                text-decoration: underline;
                            }
                        }

                        .bt-limpar {
                            text-align: center;

                            button {
                                height: 2.5rem;
                                background-color: #FFFFFF;
                                color: #000;
                                border: white solid 0.15rem;
                                font-size: 1rem;
                                padding: 0.3rem 2rem;
                                border-radius: 2rem;

                                img {
                                    height: 1.5rem;
                                    margin-right: 0.5rem;
                                }
                            }

                            button:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }

                .n-div {
                    display: flex;
                    justify-content: space-between;

                    .nresultados {
                        margin: 0.5rem 0.5rem 0.5rem 0.5rem;

                        label {
                            color: #005516;
                            font-size: 0.8rem;
                            font-family: "Montserrat-Bold";
                            margin-top: 0.5rem;
                        }
                    }

                    .npagina {
                        margin: 0.5rem 0.5rem 0.5rem 0.5rem;
                        text-align: end;

                        label {
                            color: #005516;
                            font-size: 0.8rem;
                            font-family: "Montserrat-Medium";
                            margin: 0.5rem .5rem 0 0;
                        }

                        select {
                            border: none;
                            background-color: #edf2ee;
                            font-size: 0.9rem;
                            margin-top: 0.5rem;
                            cursor: pointer;
                        }
                    }
                }

                .container-tabela {
                    margin: .5rem 0 1.5rem 0;
                    border-radius: 1.5rem;
                    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

                    .resultados {
                        width: 100%;
                        height: max-content;
                        margin: 0;
                    }
                }
            }
        }
    }
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
</style>