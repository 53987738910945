import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class OnusApi {
  cancelTokens = {
    getOnuses: null,
    getOnus: null,
    setOnus: null,
    destroyOnus: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getOnuses({ page = 1, qtd_por_pagina, nome, ativo }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      ativo,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOnuses = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/onus?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOnus = null;
    return data;
  }

  async getOnus(onusId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOnus = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/onus/${onusId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOnus = null;

    return data;
  }
  

  async destroyOnus(onusId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyOnus = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/onus/${onusId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOnus = null;
    return data;
  }

  async setOnus(onus) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setOnus = source;

    if (onus.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/onus/${onus.id}`,
        {
          cancelToken: source.token,
          ...onus,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setOnus = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/onus`,
        {
          cancelToken: source.token,
          ...onus,
        }
        //
      );

      this.cancelTokens.setOnus = null;

      return data;
    }
  }

}

export default OnusApi;
