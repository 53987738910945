<template>
  <div class="div-conteudo">
    <transition name="modal" v-if="carregando">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
            <label>Aguarde. Carregando.</label>
          </div>
        </div>
      </div>
    </transition>
    <div class="fora" v-else>
      <div class="index centro">
        <div class="topo-index">
          <h1>Cessões</h1>
          <router-link v-if="temPermissao.admin" :to="{
            name: 'CessaoNew',
          }">
            <span>+</span> Nova Cessão
          </router-link>
        </div>
        <div class="caixa-pesquisa">
          <h2 @click="tooglePesquisa()">
            Pesquisa
            <span>
              <template v-if="!exibirPesquisa">&#9650;</template>
              <template v-else>&#9660;</template>
            </span>
          </h2>
          <transition name="slide">
            <form @submit.prevent="fazerBusca" v-if="!exibirPesquisa">
              <div>
                <div class="row2">
                  <div class="item">
                    <label>CPF do(a) servidor(a):</label>
                    <the-mask :mask="['###.###.###-##']" placeholder="Digite o CPF do(a) servidor(a)" v-model="filtro.cpf"/>
                  </div>
                  <div class="item">
                    <label>Nome do(a) servidor(a):</label>
                    <input type="text" placeholder="Digite o nome do(a) servidor(a)" v-model="filtro.nome_servidor">
                  </div>
                </div>
                <div v-if="temPermissao.admin || temPermissao.gpd" class="row2">
                  <div class="item">
                    <label for="selectOrgaoOrigem">Órgão de origem:</label>
                    <v-select
                        class="vselect" 
                        v-model="selectedOrgaoOrigem" 
                        id="selectOrgaoOrigem" 
                        :options="paginated"
                        label="nome" 
                        placeholder="Todos"
                        :filterable="false" 
                        @search="onSearch">
                      <li slot="list-footer" class="pagination">
                          <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                          <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                      </li>
                    </v-select>
                  </div>
                  <div class="item">
                    <label for="selectOrgaoDestino">Órgão de destino:</label>
                    <v-select
                        class="vselect" 
                        v-model="selectedOrgaoDestino" 
                        id="selectOrgaoDestino" 
                        :options="paginated"
                        label="nome" 
                        placeholder="Todos"
                        :filterable="false" 
                        @search="onSearch">
                      <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                      </li>
                    </v-select>
                  </div>
                </div>
                <div v-else class="row2">
                  <div class="item">
                    <label for="selectOrgaoOrigem">Órgão de origem:</label>
                    <v-select
                        class="vselect" 
                        v-model="selectedOrgaoOrigem" 
                        id="selectOrgaoOrigem" 
                        :options="paginated"
                        label="nome" 
                        placeholder="Todos"
                        :filterable="false" 
                        @search="onSearch"
                        @input="travarSelecaoOrgaoOrigem"
                        :disabled="isDisabledOrigem">
                      <li slot="list-footer" class="pagination">
                          <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                          <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                      </li>
                    </v-select>
                  </div>
                  <div class="item">
                    <label for="selectOrgaoDestino">Órgão de destino:</label>
                    <v-select
                        class="vselect" 
                        v-model="selectedOrgaoDestino" 
                        id="selectOrgaoDestino" 
                        :options="paginated"
                        label="nome" 
                        placeholder="Todos"
                        :filterable="false" 
                        @search="onSearch"
                        @input="travarSelecaoOrgaoDestino"
                        :disabled="isDisabledDestino">
                      <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                      </li>
                    </v-select>
                  </div>
                </div>
                <div class="row2-data">
                  <div class="item">
                    <label>Data de início da cessão:</label>
                    <date-picker
                      v-model="filtro.data_inicio"
                      format="DD/MM/YYYY"
                      type="date" >
                    </date-picker>
                  </div>
                  <div class="item">
                    <label> Data de término da cessão: </label>
                    <date-picker
                      v-model="filtro.data_termino"
                      format="DD/MM/YYYY"
                      type="date"
                    ></date-picker>
                  </div>
                </div>
                <div class="row3-data">
                  <!--
                  <div class="item">
                    <label> Data de entrada do processo: </label>
                    <date-picker
                      v-model="filtro.data_entrada_processo"
                      format="DD/MM/YYYY"
                      type="date"
                    ></date-picker>
                  </div>-->
                  <div class="item">
                    <label for="selectedConvenio">Convênio:</label>
                    <v-select v-model="filtro.convenio_id" id="selectedConvenio" :options="optionsConvenios"
                      :reduce="nome => nome.id" label="nome" placeholder="Todos">
                    </v-select>
                  </div>
                  <div class="item">
                    <label for="selectedStatus">Status:</label>
                    <v-select v-model="filtro.status_id" id="selectedStatus" :options="optionsStatus"
                      :reduce="nome => nome.id" label="nome" placeholder="Todos">
                    </v-select>
                  </div>
                  <div class="item">
                      <label>Número do Processo SEI:</label>
                      <input type="text" placeholder="Digite o número do processo SEI" v-model="filtro.numero_processo_sei">
                    </div>
                </div>
              </div>
              <div class="botoes-pesquisa">
                <div class="bt-pesquisa">
                  <button title="Pesquisar" type="submit">
                    <img src="../../assets/imagens/pesquisa.png" class="pesquisa" alt=" " />
                    Pesquisar
                  </button>
                </div>
                <div class="bt-limpar">
                  <button title="Limpar" @click="limparFiltro()">
                    <img src="../../assets/imagens/icon-eraser.png" class="limpar" alt=" " />
                    Limpar Filtros
                  </button>
                </div>
              </div>
            </form>
          </transition>
        </div>
        <div class="n-div">
          <div class="nresultados" v-show="!carregando">
            <label>Total de Resultados: {{ this.totalResultadosTexto }}</label>
          </div>
          <div class="npagina" v-show="!carregando">
            <label>Resultados por Página </label>
            <select :value="qtd_por_pagina" @input="mudouQtdPagina">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
        <div v-if="temPermissao.admin" class="container-tabela"><!--:optionsOrgaosConveniados="optionsOrgaosConveniados" se precisar-->
          <TabelaResultadosCessoes :cessoes="cessoes" :cessao="cessao"
            v-if="!carregando && !erro && !erroFiltro"
            @excluir-linha="excluirLinha"
            @cancelar-popup="carregarCessoes">
          </TabelaResultadosCessoes>
        </div>
        <div v-else class="container-tabela">
          <TabelaResultadosCessoesUsuario :cessoes="cessoes" :cessao="cessao"
            v-if="!carregando && !erro && !erroFiltro"
            @excluir-linha="excluirLinha"
            @cancelar-popup="carregarCessoes">
          </TabelaResultadosCessoesUsuario>
        </div>
        <paginate v-if="!carregandoFiltro && !carregando" v-model="pagina" :page-count="pageCount"
          :click-handler="mudouPagina" :prev-text="'Anterior'" :next-text="'Próxima'" :container-class="'className'"
          active-class="active-page">
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
  import Paginate from "vuejs-paginate";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/locale/pt-br";
  import "vue2-datepicker/index.css";
  import tryParseInt from "../../helpers/tryParseInt";
  import metaDados from "../../helpers/metaDados";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import { format, parseISO } from "date-fns";
  import Auth from '../../services/Auth';

  import TabelaResultadosCessoes from "../../components/cessoes/TabelaResultadosCessoes.vue";
  import TabelaResultadosCessoesUsuario from "../../components/cessoes/TabelaResultadosCessoesUsuario.vue";
  import CessaoApi from "../../services/CessaoApi";

  export default {
    name: "Cessao",
    metaInfo() {
      return {
        title: "Cessões",
        titleTemplate: "%s - Casa Civil do Estado de Goiás",
        meta: [
          {
            vmid: "description",
            name: "description",
            property: "description",
            content:
              "Página de acesso e pesquisa de todas as cessões realizadas.",
          },
          {
            vmid: "og:description",
            property: "og:description",
            content:
              "Página de acesso e pesquisa de todas as cessões realizadas.",
          },
          {
            vmid: "og:title",
            property: "og:title",
            content: "Cessões - Casa Civil do Estado de Goiás",
          },
          ...metaDados(this.$route),
        ],
      };
    },
    data() {
      return {
        usuario: null,
        usuarioOrgaoId: null,
        temPermissao: null,
        isDisabledOrigem: false,
        isDisabledDestino: false,
        cessoes: [],
        cessao: null,
        servidores: [],
        optionsServidores: [],
        orgaosConveniados: [],
        optionsOrgaosConveniados: [],
        selectedOrgaoOrigem: null,
        selectedOrgaoDestino: null,
        search: '',
        offset: 0,
        limit: 10,
        convenios: [],
        optionsConvenios: [],
        status: [],
        optionsStatus: [],
        carregando: true,
        erro: false,
        totalResultados: null,
        totalResultadosTexto: null,
        pagina: 1,
        qtd_por_pagina: 10,
        filtro: {
          nome_servidor: null,
          cpf: null,
          orgao_origem_id: null,
          orgao_destino_id: null,
          data_inicio: null,
          data_termino: null,
          convenio_id: null,
          status_id: null,
          numero_processo_sei: null,
        },
        exibirPesquisa: true,
        carregandoFiltro: false,
        erroFiltro: false,
      };
    },
    components: {
      TabelaResultadosCessoes,
      TabelaResultadosCessoesUsuario,
      Paginate,
      vSelect,
      DatePicker,
    },
    created() {
      this.cessaoApi = new CessaoApi();
      let auth = new Auth();
      this.carregarQtdPorPagina();
      this.carregarValoresFiltro();
      this.carregarCessoes();
      this.temPermissao = auth.temPermissao;
      this.usuario = auth.usuario;
      this.retornarOrgaoIdUsuario();
    },
    watch: {
      $route: function (to, from) {
        const mudouQuery =
          to.query.pagina != from.query.pagina ||
          to.query.qtd_por_pagina != from.query.qtd_por_pagina ||
          to.query.nome_servidor != from.query.nome_servidor ||
          to.query.cpf != from.query.cpf ||
          to.query.orgao_origem_id != from.query.orgao_origem_id ||
          to.query.orgao_destino_id != from.query.orgao_destino_id ||
          to.query.data_inicio != from.query.data_inicio ||
          to.query.data_termino != from.query.data_termino ||
          to.query.convenio_id != from.query.convenio_id ||
          to.query.status_id != from.query.status_id || 
          to.query.numero_processo_sei != from.query.numero_processo_sei;
        if (
          from.name == "Cessao" &&
          to.name == "Cessao" &&
          mudouQuery
        ) {
          this.carregarValoresFiltro();
          this.carregarCessoes();
        }
      },
    },
    methods: {
      onSearch(query) {
        this.search = query
        this.offset = 0
      },
      async retornarOrgaoIdUsuario() {
        try {
          this.carregando = true;
          this.erro = false;

          if (this.cessaoApi.cancelTokens.getOrgaoIdUsuarioLogado) {
            this.cessaoApi.cancelTokens.getOrgaoIdUsuarioLogado.cancel();
          }

          const dadosUsuarioOrgao = await this.cessaoApi.getOrgaoIdUsuarioLogado(this.usuario.orgao.id);
          this.usuarioOrgaoId = dadosUsuarioOrgao[0]

        } catch (err) {
          if (this.cessaoApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;
          this.usuarioOrgaoId = null;
        }
      },
      travarSelecaoOrgaoOrigem() {
        this.isDisabledDestino = true;

        if(this.usuarioOrgaoId.id == this.selectedOrgaoOrigem.id) {
          this.isDisabledDestino = false;
        } else {
          this.selectedOrgaoDestino = this.usuarioOrgaoId
        }
      },
      travarSelecaoOrgaoDestino() {
        this.isDisabledOrigem = true;

        if(this.usuarioOrgaoId.id == this.selectedOrgaoDestino.id) {
          this.isDisabledOrigem = false;
        } else {
          this.selectedOrgaoOrigem = this.usuarioOrgaoId
        }
      },
      setarOptions() {
        for (let i = 0; i < this.orgaosConveniados.length; i++) {
          this.optionsOrgaosConveniados[i] = {
            id: this.orgaosConveniados[i].id,
            nome: this.orgaosConveniados[i].nome
          }
        }
        for (let i = 0; i < this.servidores.length; i++) {
          this.optionsServidores[i] = {
            id: this.servidores[i].id,
            nome: this.servidores[i].nome
          }
        }
        for (let i = 0; i < this.convenios.length; i++) {
          this.optionsConvenios[i] = {
            id: this.convenios[i].id,
            nome: this.convenios[i].nome
          }
        }
        for (let i = 0; i < this.status.length; i++) {
          this.optionsStatus[i] = {
            id: this.status[i].id,
            nome: this.status[i].nome
          }
        }
      },
      tooglePesquisa: function () {
        this.exibirPesquisa = !this.exibirPesquisa;
      },
      async excluirLinha(cessaoId) {
        try {
          this.carregando = true;
          this.erro = false;

          if (this.cessaoApi.cancelTokens.destroyCessoes) {
            this.cessaoApi.cancelTokens.destroyCessoes.cancel();
          }

          await this.cessaoApi.destroyCessoes(cessaoId);

          this.carregarCessoes();
          this.carregarQtdPorPagina();
          this.carregarValoresFiltro();

        } catch (err) {
          if (this.cessaoApi.isCancel(err)) {
            return;
          }

          this.carregando = false;
          this.erro = true;
        }
      },
      fazerBusca() {
        if (this.selectedOrgaoOrigem !== null) {
          this.filtro.orgao_origem_id = this.selectedOrgaoOrigem.id
        } else {
          this.filtro.orgao_origem_id = undefined
        }
        if (this.selectedOrgaoDestino !== null) {
          this.filtro.orgao_destino_id = this.selectedOrgaoDestino.id
        } else {
          this.filtro.orgao_destino_id = undefined
        }
        this.$router.push({
          name: "Cessao",
          query: {
            ...this.filtro,
            data_inicio: this.filtro.data_inicio
              ? format(this.filtro.data_inicio, "yyyy-MM-dd")
              : null,
            data_termino: this.filtro.data_termino
              ? format(this.filtro.data_termino, "yyyy-MM-dd")
              : null,
            pagina: 1,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      mudouQtdPagina: function (event) {
        const novaQtdPagina = parseInt(event.target.value);
        if (novaQtdPagina < this.qtd_por_pagina) {
          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(this.pagina);
        } else {
          let novaPagina = Math.ceil(
            (this.qtd_por_pagina * this.pagina) / novaQtdPagina
          );

          this.qtd_por_pagina = novaQtdPagina;
          this.mudouPagina(novaPagina);
        }
      },
      getParam: function (name) {
        if (this.$route && this.$route.query) {
          return this.$route.query[name];
        }
        return null;
      },
      mudouPagina: function (page) {
        this.pagina = page;
        const filtro = {
          nome_servidor: this.getParam("nome_servidor"),
          cpf: this.getParam("cpf"),
          orgao_origem_id: this.getParam("orgao_origem_id"),
          orgao_destino_id: this.getParam("orgao_destino_id"),
          data_inicio: this.getParam("data_inicio"),
          data_termino: this.getParam("data_termino"),
          convenio_id: this.getParam("convenio_id"),
          status_id: this.getParam("status_id"),
          numero_processo_sei: this.getParam("numero_processo_sei"),
        };

        this.$router.push({
          name: "Cessao",
          query: {
            ...filtro,
            pagina: this.pagina,
            qtd_por_pagina: this.qtd_por_pagina,
          },
        });
      },
      carregarValoresFiltro: async function () {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false

          const dadosOrgaosConveniados = await this.cessaoApi.getOrgaosConveniados();
          this.orgaosConveniados = dadosOrgaosConveniados

          this.orgaosConveniados.map((orgao) => {
            if (orgao.sigla !== null) {
              orgao.nome = orgao.sigla + " - " + orgao.nome
            }
          })

          const dadosServidores = await this.cessaoApi.getServidores();
          this.servidores = dadosServidores

          const dadosConvenios = await this.cessaoApi.getConvenios();
          this.convenios = dadosConvenios

          const dadosStatus = await this.cessaoApi.getStatus();
          this.status = dadosStatus

          this.setarOptions();
        } catch (err) {
          console.log("carregarValoresFiltro", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.orgaosConveniados = [];
          this.servidores = [];
          this.convenios = [];
          this.status = [];
        }
      },
      carregarCessoes: async function () {
        try {
          this.carregando = true;
          this.erro = false;

          this.qtd_por_pagina = this.getParam("qtd_por_pagina") || this.qtd_por_pagina;

          this.armazenarQtdPorPagina();

          const pagina = this.getParam("pagina") || 1;
          this.pagina = parseInt(pagina);

          this.filtro = {
            nome_servidor: this.getParam("nome_servidor"),
            cpf: this.getParam("cpf"),
            orgao_origem_id: this.getParam("orgao_origem_id"),
            orgao_destino_id: this.getParam("orgao_destino_id"),
            data_inicio: this.getParam("data_inicio")
              ? parseISO(this.getParam("data_inicio"))
              : null,
            data_termino: this.getParam("data_termino")
              ? parseISO(this.getParam("data_termino"))
              : null,
            convenio_id: this.getParam("convenio_id"),
            status_id: this.getParam("status_id"),
            numero_processo_sei: this.getParam("numero_processo_sei"),    
          };

          if (this.cessaoApi.cancelTokens.getCessoes) {
            this.cessaoApi.cancelTokens.getCessoes.cancel();
          }

          let data = await this.cessaoApi.getCessoes({
            ...this.filtro,
            qtd_por_pagina: this.qtd_por_pagina,
            page: this.pagina,
          });

          this.cessoes = data.resultados;
          this.totalResultados = data.total_resultados;
          if (data.total_resultados >= 1000) {
            this.totalResultadosTexto = (data.total_resultados / 1000).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
          } else {
            this.totalResultadosTexto = data.total_resultados;
          }
          this.carregando = false;
        } catch (err) {
          if (this.cessaoApi.isCancel(err)) {
            return;
          }
          this.carregando = false;
          this.erro = true;
        }
      },
      limparFiltro: function () {
        this.tooglePesquisa();
        this.filtro = {
          nome_servidor: undefined,
          cpf: undefined,
          orgao_origem_id: undefined,
          orgao_destino_id: undefined,
          data_inicio: undefined,
          data_termino: undefined,
          convenio_id: undefined,
          status_id: undefined,
          numero_processo_sei: undefined,
        };
        this.selectedOrgaoOrigem = null
        this.selectedOrgaoDestino = null
        this.isDisabledOrigem = false
        this.isDisabledDestino = false
      },
      armazenarQtdPorPagina() {
        localStorage.setItem(
          "qtd_por_pagina_orgao",
          this.qtd_por_pagina
        );
      },
      carregarQtdPorPagina() {
        const qtd = localStorage.getItem("qtd_por_pagina_orgao");
        if (qtd) {
          this.qtd_por_pagina = tryParseInt(qtd);
        }
      },
    },
    computed: {
      pageCount: function () {
        if (
          this.totalResultados != undefined &&
          this.totalResultados != null &&
          this.qtd_por_pagina != null &&
          this.qtd_por_pagina != undefined &&
          this.totalResultados > 0 &&
          this.qtd_por_pagina > 0
        ) {
          return Math.ceil(this.totalResultados / this.qtd_por_pagina);
        }
        return 0;
      },
      filtered() {
        return this.orgaosConveniados.filter((orgao) =>
          orgao.nome.toLowerCase().includes(this.search.toLowerCase())
        )
      },
      paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset)
      },
      hasNextPage() {
        const nextOffset = this.offset + this.limit
        return Boolean(
          this.filtered.slice(nextOffset, this.limit + nextOffset).length
        )
      },
      hasPrevPage() {
        const prevOffset = this.offset - this.limit
        return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
        )
      }
    },
  };
</script>

<style lang="scss" scoped>
  /* Remove as setas de aumento e diminuição do input tipo "number" */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  .pagination {
    display: flex;
    gap: 5rem;
    margin: 0.5rem 0.5rem 0 .5rem;
  }
  .pagination button {
    display: inline-block;
    border: none;
    background-color: #005516;
    font-weight: bold;
    font-size: .8rem;
    cursor: pointer;
    color: white;
    border-radius: 2rem;
    flex-grow: 1;
  }
  .pagination button:hover {
    background-color: #4CAF50;
    cursor: pointer;
  }

  @media screen and (min-width: "1066px") {
    .div-conteudo {
      width: 100vw;
      .centro {
        max-width: 96vw;
        margin: 0 auto;
      }
      .index {
        .topo-index {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            font-size: 1.8rem;
          }
          a {
            color: #005516;
            text-decoration: none;
            font-family: "Montserrat-Medium";
            border: #005516 solid 0.15rem;
            padding: 0.3rem 2rem;
            border-radius: 0.2rem;
            transition: 0.2s;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            span {
              font-family: "Montserrat-Bolder";
            }
          }
          a:hover {
            color: white;
            background-color: #005516;
          }
        }
        .caixa-pesquisa {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem 1rem 0 0;
          .row4 {
            display: grid;
            grid-template-columns: .25fr .25fr .25fr .25fr;
            gap: 1rem;
            .item {
              width: -webkit-fill-available;
              .mx-datepicker {
                border: none;
              }
            }
          }
          .row2 {
            display: grid;
            grid-template-columns: .5fr .5fr;
            gap: 1rem;
            .item {
              width: -webkit-fill-available;
              .mx-datepicker {
                border: none;
              }
            }
          }
          .row2-data {
            display: grid;
            grid-template-columns: .5fr .5fr;
            gap: 1rem;
            .item {
              width: -webkit-fill-available;
              .mx-datepicker {
                border: none;
              }
            }
          }
          .row3-data {
            display: grid;
            grid-template-columns: .5fr .5fr .5fr;
            gap: 1rem;
            .item {
              width: -webkit-fill-available;
              .mx-datepicker {
                border: none;
              }
            }
          }
          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }
          h2 {
            margin: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
          }
          .item {
            display: grid;
            margin: .5rem 0 .5rem 0;
            label {
              width: fit-content;
              margin-bottom: 0.3rem;
              color: black;
              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
              font-weight: 500;
            }
            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }
            select {
              height: 2rem;
              border-radius: 0.5rem;
              border: solid 0.1rem;
              background-color: white;
              font-family: "Montserrat-Medium";
              font-size: 0.84rem;
            }
          }
          .botoes-pesquisa {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;

            .bt-pesquisa {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #005516;
                color: white;
                border: #005516 solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }

            .bt-limpar {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #FFFFFF;
                color: #000;
                border: white solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1.5rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .n-div {
          display: flex;
          justify-content: space-between;
          .nresultados {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;

            label {
              color: #005516;
              font-size: 0.9rem;
              font-family: "Montserrat-Bold";
              margin-top: 0.5rem;
            }
          }
          .npagina {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;

            label {
              color: #005516;
              font-size: 0.9rem;
              font-family: "Montserrat-Medium";
              margin: 0.5rem .5rem 0 0;
            }
            select {
              border: none;
              background-color: #edf2ee;
              font-size: 0.9rem;
              margin-top: 0.5rem;
              cursor: pointer;
            }
          }
        }
        .container-tabela {
          margin-top: .5rem;
          border-radius: 1.5rem;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
          .resultados {
            width: 100%;
            height: max-content;
            margin: 0;
          }
        }
      }
    }
  }

  @media screen and (min-width: "60px") and (max-width: "1065px") {
    .div-conteudo {
      width: 100vw;
      .centro {
        width: 90vw;
        margin: 0 auto;
      }
      .index {
        .topo-index {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;

          h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            font-size: 1.8rem;
          }
          a {
            color: #005516;
            text-decoration: none;
            font-family: "Montserrat-Medium";
            border: #005516 solid 0.15rem;
            padding: 0.3rem 2rem;
            border-radius: 0.2rem;
            transition: 0.2s;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;

            span {
              font-family: "Montserrat-Bolder";
            }
          }
          a:hover {
            color: white;
            background-color: #005516;
          }
        }
        .caixa-pesquisa {
          background-color: #dbdbdb;
          padding: 1rem;
          border-radius: 1rem 1rem 0 0;
          .row4-data {
            display: flex;
            justify-content: space-between;
            gap: 1.5rem;

            .item {
              width: -webkit-fill-available;
            }
          }
          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }
          h2 {
            margin: 0;
            text-transform: uppercase;
            color: #005516;
            font-size: 1.2rem;
            font-family: "Montserrat-Bold";
            cursor: pointer;
          }
          .item {
            display: grid;
            margin: .5rem 0 .5rem 0;

            label {
              width: fit-content;
              margin-bottom: 0.3rem;
              color: black;
              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
              font-weight: 500;
            }
            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }
            select {
              height: 2rem;
              border-radius: 0.5rem;
              border: solid 0.1rem;
              background-color: white;
              font-family: "Montserrat-Medium";
              font-size: 0.84rem;
            }
          }

          .botoes-pesquisa {
            display: grid;
            gap: 1rem;
            place-content: center;
            margin-top: 1rem;

            .bt-pesquisa {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #005516;
                color: white;
                border: #005516 solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }

            .bt-limpar {
              text-align: center;

              button {
                height: 2.5rem;
                background-color: #FFFFFF;
                color: #000;
                border: white solid 0.15rem;
                font-size: 1rem;
                padding: 0.3rem 2rem;
                border-radius: 2rem;

                img {
                  height: 1.5rem;
                  margin-right: 0.5rem;
                }
              }

              button:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .n-div {
          display: flex;
          justify-content: space-between;

          .nresultados {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;

            label {
              color: #005516;
              font-size: 0.8rem;
              font-family: "Montserrat-Bold";
              margin-top: 0.5rem;
            }
          }

          .npagina {
            margin: 0.5rem 0.5rem 0.5rem 0.5rem;
            text-align: end;

            label {
              color: #005516;
              font-size: 0.8rem;
              font-family: "Montserrat-Medium";
              margin: 0.5rem .5rem 0 0;
            }

            select {
              border: none;
              background-color: #edf2ee;
              font-size: 0.9rem;
              margin-top: 0.5rem;
              cursor: pointer;
            }
          }
        }

        .container-tabela {
          margin-top: .5rem;
          border-radius: 1.5rem;
          box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

          .resultados {
            width: 100%;
            height: max-content;
            margin: 0;
          }
        }
      }
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: fit-content;
    height: 0px auto;
    margin: 0px auto;
    position: relative;
    overflow-y: auto;
    overflow-x: auto;
    font-size: 1.5rem;
    font-family: "Montserrat-Medium";

    img {
      width: 2rem;
      height: 2rem;
      margin-right: 1.6rem;
    }

    label {
      font-size: 1.3rem;
      color: rgba(255, 255, 255, 0.556);
      display: inline;
      position: relative;
      top: 0rem;
    }
  }
</style>