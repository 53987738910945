<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <ConvenioCotaForm :convenioCota="convenioCota"/>
        </div>
    </div>
</template>

<script>
    import ConvenioCotaApi from '../../services/ConvenioCotaApi';
    import ConvenioCotaForm from '../../components/convenios_cotas/ConvenioCotaForm.vue';

    export default {
        name: "ConveniosCotasEdit",
        data() {
            return {
                carregando: true,
                erro: false,
                convenioCota: null,
            };
        },
        components: {
            ConvenioCotaForm,
        },
        created() {
            this.convenioCotaApi = new ConvenioCotaApi();
            this.carregarConvenioCota();
        },
        watch: {
            $route: function (to, from) {
                if (to.params.id != from.params.id) {
                    this.carregarConvenioCota();
                }
            },
        },
        methods: {
            carregarConvenioCota: async function () {
                try {
                    this.carregando = true;
                    this.erro = false;

                    if (this.convenioCotaApi.cancelTokens.getConvenioCota) {
                        this.convenioCotaApi.cancelTokens.getConvenioCota.cancel();
                    }

                    const dadosConvenioCota = await this.convenioCotaApi.getConvenioCota(
                        this.$route.params.id
                    );
                    this.convenioCota = dadosConvenioCota

                    this.carregando = false;
                } catch (err) {
                    if (this.convenioCotaApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);

                    this.erro = true;
                    this.carregando = false;
                    this.convenioCota = null;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
</style>