<template>
    <div class="wrapper">
        <div class="topo-header centro">
            <botao-voltar nomeRota="Onus" />
            <h1>{{ onusForm.id ? "Editar" : "Novo" }} Ônus</h1>
        </div>
        <div class="tab-dados-basicos">
            <div class="form centro">
                <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" :erro="erro" @fechar-erro="fecharErro"/>
                <p>Os campos com asterisco (*) são de preenchimento obrigatório.</p>               
                <form @submit.prevent="enviarForm">
                    <div class="caixa-form item">
                        <div class="flexbox-2">
                            <div class="item">
                                <label><b>{{ possuiAsterisco("Nome:") }}</b></label>
                                <input type="text" placeholder="Digite o nome" v-model="onusForm.nome" />
                            </div>
                            <div class="item">
                                <div class="radio">
                                    <label class="radio-label"><b>{{ possuiAsterisco("Ativo:") }}</b></label>
                                    <div class="radio-buttons">
                                        <div class="radio-item">
                                            <label for="ativo-onus">Sim</label>
                                            <input type="radio" id="ativo-onus"
                                                :value=true v-model="onusForm.ativo">
                                        </div>                                                
                                        <div class="radio-item">
                                            <label for="ativo-onus">Não</label>
                                            <input type="radio" id="ativo-onus"
                                                :value=false v-model="onusForm.ativo">
                                        </div>
                                    </div>
                                </div>
                            </div>                            
                        </div>                     
                        <div class="bt-salvar">
                            <button id="botao-salvar" title="Criar/Atualizar" type="submit">
                                <img id="spinner-loading" class="spinner-loading"
                                    src="../../assets/imagens/loadingSpinner.gif" alt="Spinner de carregamento">
                                <p id="conteudo-botao">{{ onusForm.id ? "Atualizar" : "Criar" }}</p>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="js">
    import OnusApi from "../../services/OnusApi";
    import BotaoVoltar from "../../components/BotaoVoltar.vue";
    import MsgErroFormularios from "../MsgErroFormularios";

    export default {
        name: "OnusForm",
        props: {
            onus: {
                type: Object,
                required: false,
            },
        },
        data() {
            return {
                count: -1,
                count1: null,
                filess: null,
                carregando: true,
                erro: false,
                msgErros: {
                    nome: '',
                    ativo: '',
                },
                onusForm: this.onus
                    ? {
                        id: this.onus.id,
                        nome: this.onus.nome,
                        ativo: this.onus.ativo
                    }
                    : {
                        id: null,
                        nome: null,
                        ativo: null,
                    },
            };
        },
        components: {
            BotaoVoltar,
            MsgErroFormularios,
        },
        created() {
            this.onusApi = new OnusApi();
        },
        methods: {
            fecharErro() {
                this.erro = false;
            },
            mudarBotaoSalvar(textoBotao) {
                let botaoSalvar = document.getElementById("botao-salvar");
                let conteudoBotao = document.getElementById("conteudo-botao");
                let spinnerLoading = document.getElementById("spinner-loading");

                let novoTextoConteudoBotao = "Carregando"

                //mudar para o carregando
                botaoSalvar.disabled = true;
                botaoSalvar.style.backgroundColor = "gray"
                conteudoBotao.innerHTML = novoTextoConteudoBotao;
                conteudoBotao.style.display = "inline-block"
                spinnerLoading.style.visibility = "visible"
                spinnerLoading.style.position = "relative"

                if (this.erro == true) {
                    //mudar para o padrão (da aba dados básicos)
                    botaoSalvar.disabled = false;
                    botaoSalvar.style.backgroundColor = "#005516"
                    conteudoBotao.innerHTML = textoBotao;
                    spinnerLoading.style.visibility = "hidden"
                    spinnerLoading.style.position = "absolute"
                }
            },

            async enviarForm() {
                let conteudoBotao = document.getElementById("conteudo-botao");
                const textoConteudoBotao = conteudoBotao.innerHTML;
                
                let flag = this.validarForm(this.onusForm);

                if (flag == 1) {
                    this.erro = true;
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return false
                }

                try {
                    this.carregando = true;
                    this.erro = false;
                    

                    this.mudarBotaoSalvar(textoConteudoBotao);

                    if (this.onusApi.cancelTokens.setOnus) {
                        this.onusApi.cancelTokens.setOnus.cancel();
                    }

                    let data;
                    data = await this.onusApi.setOnus(
                        this.onusForm
                    );

                    var rota = this.$router;
                    setTimeout(function () {
                        rota.push({
                            name: "OnusShow",
                            params: {
                                id: data.id,
                            },
                        });
                    }, 1000);
                    //window.location.reload();
                } catch (err) {
                    if (this.onusApi.isCancel(err)) {
                        return;
                    }
                    console.log(err);
                    this.carregando = false;
                    this.erro = true;

                    this.mudarBotaoSalvar(textoConteudoBotao)

                    const { response } = err;
                    if (response && response.data) {
                        this.msgErros = response.data;
                    } else {
                        this.msgErros =
                            "Houve um erro ao salvar o registro. Por favor tente novamente.";
                    }
                }
            },
            validarForm(onusForm) {
                let flag = 0;

                //Resetar as mensagens de erro
                this.msgErros.nome = ''
                this.msgErros.ativo = ''
                this.msgErros.default = ''

                if(onusForm.nome == null || onusForm.nome == ''){
                    this.msgErros.nome = "O campo Nome não foi preenchido.";
                    flag = 1;
                }
                
                if(onusForm.ativo == null){
                    this.msgErros.ativo = "O campo Ativo não foi preenchido.";
                    flag = 1;
                } 

                return flag;
            },
            possuiAsterisco(texto) {
                var texto_final = "* " + texto
                return texto_final
            },
            
        },

    };
</script>

<style lang="scss">
    * {
        margin: 0;
        padding: 0;
        font-family: 'Karla', sans-serif;
    }

    @media screen and (min-width: "1066px") {
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            box-shadow: #005516 0px 5px 15px;

            .flexbox-1 {
                display: grid;
                grid-template-columns: 1fr .5fr 2fr;
                gap: 2rem;
            }

            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .8rem;
                margin: 1.5rem 0 0 0;
            }

            .btn-carregar-dados:hover {
                background-color: #139b2cbc;
            }

            .flexbox-2 {
                display: flex;
                justify-content: space-between;
                gap: 2rem;
            }

            .flexbox-3 {
                display: grid;
                grid-template: "a b c";
                grid-template-columns: 1.5fr 3fr 1.5fr;
                gap: 1.5rem;
            }

            input,
            the-mask {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                            border-radius: 0.5rem;
                            border: none;
                            width: 44.27rem;
                        } */
            input[type="file"] {
                display: none;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: grid;
                margin: .5rem 0;
                width: -webkit-fill-available;

                .radio {
                    display: inline-flex;
                    align-items: center;
                    height: fit-content;
                    width: fit-content;
                }

                .radio-buttons {
                    display: flex;
                }

                .radio-label {
                    margin-bottom: 0;
                }

                .radio-item {
                    display: flex;
                    align-items: center;

                    label {
                        margin: 0 0 0 .8rem;
                        font-size: .8rem;
                    }

                    input {
                        width: fit-content;
                        margin-left: .5rem;
                    }
                }

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                /*  .upload {
                                color: #ffa600;
                                border-radius: 0.5rem;
                                border: #ffa600 solid 0.124rem;
                                display: inline-block;
                                padding: 2px 6px;
                                font-size: 0.9rem;
                                transition: 0.2s;
                                margin-left: 0.4rem;
                                margin-right: 0.4rem;
                                font-family: "Montserrat-Medium";
                            }
                            .upload:hover{
                                color: white;
                                background-color: #ffa600;
                            } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }
            }
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
                p {
                    margin: 0;
                }
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }
    }

    @media screen and (min-width: "60px") and (max-width: "1065px") {
        .wrapper {
            width: 100%;
            min-height: 100vh;
            background-color: #f8f8f8;
            margin: 0;
            padding: 20px;
        }

        h1 {
            font-family: "Montserrat-Bold";
            color: #ffa600;
            margin-left: 1rem;
            font-size: 1.8rem;
        }

        .topo-header {
            display: flex;
            align-items: center;

            img {
                height: 1.5rem;
            }
        }

        .caixa-form {
            background-color: #dbdbdb;
            padding: 1rem;
            border-radius: 1rem;
            border: 2px solid #00a339;

            .btn-carregar-dados {
                align-self: center;
                width: fit-content;
                height: fit-content;
                cursor: pointer;
                color: #fff;
                background-color: #139b2b;
                border: none;
                font-size: .8rem;
                border-radius: 2rem;
                padding: .2rem .8rem;
            }

            .btn-carregar-dados:hover {
                background-color: #139b2cbc;
            }

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: grid;
                margin: 1rem 0;

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                        color: #ffa600;
                        border-radius: 0.5rem;
                        border: #ffa600 solid 0.124rem;
                        display: inline-block;
                        padding: 2px 6px;
                        font-size: 0.9rem;
                        transition: 0.2s;
                        margin-left: 0.4rem;
                        margin-right: 0.4rem;
                        font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                        color: white;
                        background-color: #ffa600;
                        } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: calc(100vw - 5.5rem);
            }
        }

        .caixa-form-blocos {
            background-color: #efefef;
            padding: 1rem;
            border-radius: 0.2rem;

            input {
                border-radius: 0.5rem;
                border: solid 0.1rem;
            }

            /* input[type="file"]{
                        border-radius: 0.5rem;
                        border: none;
                        width: 44.27rem;
                    } */
            input[type="file"] {
                display: none;
            }

            .custom-file-upload {
                border-radius: 0.5rem;
                border: solid 0.1rem;
                display: inline-block;
                padding: 6px 12px;
                margin-left: 0.4rem;
                margin-right: 0.4rem;
                cursor: pointer;
            }

            input:disabled {
                background: white;
            }

            h2 {
                margin-top: 0;
                text-transform: uppercase;
                color: #005516;
                font-size: 1.2rem;
                font-family: "Montserrat-Bold";
                cursor: pointer;
            }

            .item {
                display: block;
                margin: 1rem 0;

                label {
                    display: inline;
                    margin-bottom: 0.3rem;
                    color: black;
                    font-family: "Montserrat-Medium";
                    font-size: 0.9rem;
                }

                input {
                    height: 2rem;
                    font-family: "Montserrat-Medium";
                    padding-left: 0.5rem;
                    font-size: 0.9rem;
                }

                a:link {
                    color: #ffa600;
                }

                a:link:hover {
                    color: #e49503;
                }

                select {
                    height: 2rem;
                    width: 100%;
                    border-radius: 0.5rem;
                    border: solid 0.1rem;
                    background-color: white;
                    font-family: "Montserrat-Medium";
                    font-size: 0.95rem;
                }

                .upload {
                    background-color: #ffa600;
                    color: white;
                    border: none;
                    font-size: 0.9rem;
                    padding: 2px 6px;
                    border-radius: 0.2rem;
                    margin-left: 0.4rem;
                    margin-right: 0.4rem;
                }

                /* .upload{
                        color: #ffa600;
                        border-radius: 0.5rem;
                        border: #ffa600 solid 0.124rem;
                        display: inline-block;
                        padding: 2px 6px;
                        font-size: 0.9rem;
                        transition: 0.2s;
                        margin-left: 0.4rem;
                        margin-right: 0.4rem;
                        font-family: "Montserrat-Medium";
                        }
                        .upload:hover{
                        color: white;
                        background-color: #ffa600;
                        } */
                .textarea {
                    width: 100%;
                    border-radius: 10px;
                    box-shadow: 0 0 0 1px #000;
                    border: 5px solid transparent;
                    outline: none;
                    padding: 4px;
                    min-height: 150px;
                    box-sizing: border-box;
                    font-family: "Montserrat-Medium";
                    font-size: 0.93rem;
                    color: black;
                }

                .textarea:focus {
                    outline: 1px solid !important;
                    box-shadow: 0 0 0 2px black;
                }

                .mx-datepicker {
                    width: 100%;
                }

                .checkbox {
                    input {
                        width: auto;
                    }

                    .check {
                        --background: #fff;
                        --border: #d1d6ee;
                        --border-hover: #bbc1e1;
                        --border-active: #1b5138;
                        --tick: #fff;
                        position: relative;
                        cursor: pointer;

                        input,
                        svg {
                            width: 21px;
                            height: 21px;
                            display: inline-block;
                        }

                        input {
                            appearance: none;
                            -moz-appearance: none;
                            position: relative;
                            outline: none;
                            background: var(--background);
                            border: none;
                            margin: 0;
                            transform: translateY(5px);
                            padding: 0;
                            cursor: pointer;
                            border-radius: 4px;
                            transition: box-shadow 0.3s;
                            box-shadow: inset 0 0 0 var(--s, 1px) var(--b, var(--border));

                            &:hover {
                                --s: 2px;
                                --b: var(--border-hover);
                            }

                            &:checked {
                                --b: var(--border-active);
                            }
                        }

                        svg {
                            pointer-events: none;
                            fill: none;
                            stroke-width: 2px;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            stroke: var(--stroke, var(--border-active));
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 21px;
                            height: 21px;
                            transform: scale(var(--scale, 1)) translateZ(1);
                            margin-top: 0rem;
                        }

                        span {
                            transform: translateY(-5px);
                            display: inline;
                            margin-left: 0.5rem;
                        }

                        &.path {
                            input {
                                &:checked {
                                    --s: 2px;
                                    transition-delay: 0.4s;

                                    &+svg {
                                        --a: 16.1 86.12;
                                        --o: 102.22;
                                    }
                                }
                            }

                            svg {
                                stroke-dasharray: var(--a, 86.12);
                                stroke-dashoffset: var(--o, 86.12);
                                transition: stroke-dasharray 0.6s, stroke-dashoffset 0.6s;
                            }
                        }
                    }
                }
            }

            .multiselect {
                width: 100%;
            }
        }

        .blocos-textos {
            margin: 1rem 0rem;
        }

        .bt-salvar {
            text-align: center;
            margin: 1.5rem 0 0 0;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
                p {
                    margin: 0;
                }
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-salvar-tab {
            text-align: center;
            margin: 1rem;

            button {
                background-color: #005516;
                color: white;
                border: none;
                font-size: 1rem;
                padding: 0.4rem 2rem;
                border-radius: 2rem;
                margin: 0.2rem;
            }

            label {
                display: none;
                padding-left: 10px;
                width: 10px;
            }
        }

        .bt-excluir {
            img {
                cursor: pointer;
                width: 23px;
                padding: 0.2rem;
                height: 1.4rem;
                display: inline-block;
            }

            button {
                border-radius: 1rem;
                border: none;
            }
        }

        .spinner-loading {
            visibility: hidden;
            position: absolute;
            width: 1.2rem;
            height: 1.2rem;
            margin: 0rem .5rem 0rem -0.5rem;
        }

        #botao-salvar:hover {
            background-color: #4CAF50;
        }

        #botao-salvar-tab:hover {
            background-color: #4CAF50;
        }
    }
</style>
