<template>
  <div id='faixas_convenios'>
    <div class="itemTop">
      <label>Adicionar Faixas: </label>
      <div class="bt-incluir">
        <button type="button" @click="adicionarFaixas()">
          <span>+</span> Adicionar
        </button>
      </div>
    </div>
    <template v-for="(faixas_convenios, i) in local">
      <template>
        <div :key="faixas_convenios.id">
          <template>
            <div class="caixa-form-faixas item-faixas" v-if="faixas_convenios._destroy !== true">
              <!-- Editar -->
              <div class="divFaixas" v-if="faixas_convenios.id">
                <button type="button" @click="removerFaixa(faixas_convenios, i)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                </button>
                <div class="nome">
                  <label>{{ possuiAsterisco("Nome da faixa:") }}</label>
                  <input type="text" placeholder="Digite o nome da faixa" v-model="faixas_convenios.nome" />
                </div>
                <div class="limite">
                  <label>{{ possuiAsterisco("Limite:") }}</label>
                  <input type="number" max="9999" v-model="faixas_convenios.limite" 
                          @input="limiteContagem(faixas_convenios.limite)"/>
                  <p id="mensagem" class="mensagem"> No máximo 4 dígitos </p>
                </div>
              </div>

              <!-- Adicionar -->
              <div class="divFaixas" v-else>
                <button type="button" @click="removerFaixa(faixas_convenios, i)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                </button>
                <div class="nome">
                  <label>{{ possuiAsterisco("Nome da faixa:") }}</label>
                  <input type="text" placeholder="Digite o nome da faixa" v-model="faixas_convenios.nome"/>
                </div>
                <div class="limite">
                  <label>{{ possuiAsterisco("Limite:") }}</label>
                  <input type="number" max="9999" v-model="faixas_convenios.limite" 
                          @input="limiteContagem(faixas_convenios.limite)"/>
                  <p id="mensagem" class="mensagem"> No máximo 4 dígitos </p>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { cloneDeep, tap } from "lodash";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi.js";

  export default {
    name: "MultiplasFaixas",
    props: ["value"],
    data() {
      return {
        carregando: true,
        erro: false,
        nome: null,
        limite: null,
        numerosLimiteFaixa: [],
      }
    },
    mounted() {
      this.gerarNumeros();
    },
    computed: {
      local() {
        return this.value ? this.value : [];
      },
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
    },
    methods: {
      limiteContagem(numero) {
        let divMensagem = document.getElementById("mensagem");
        
        if(numero > 9999) {
          divMensagem.style.display = "block"
        }

        if (numero <= 9999) {
          divMensagem.style.display = "none"
        }
      },
      adicionarFaixas() {
        this.$emit(
          "input",
          tap(cloneDeep(this.local), (v) =>
            v.push({
              id: null,
              nome: null,
              limite: null,
              _destroy: null,
            })
          )
        );
      },
      removerFaixa(faixas_convenios, i) {
        let clone;
        if (faixas_convenios.id) {
          clone = tap(cloneDeep(this.local), (v) => {
            v[i] = { ...v[i], _destroy: true };
          });
        } else {
          clone = tap(cloneDeep(this.local), (v) => v.splice(i, 1));
        }
        this.$emit("input", clone);
      },
      possuiAsterisco(texto) {
        return "* " + texto;
      },
      gerarNumeros() {
        for (let i = 1; i <= 1000; i++) {
          this.numerosLimiteFaixa.push(i);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .mensagem {
    display: none;
    color: red;
  }
  p#mensagem.mensagem {
    margin: 0;
  }
  @media screen and (min-width: "1066px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .divFaixas {
      display: grid;
      grid-template: "a b c";
      grid-template-columns: .25fr 6fr 3.1fr;
      align-items: center;
      gap: 1rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        grid-template: "a b";
        grid-template-columns: .2fr .8fr;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      .limite {
        display: grid;
        grid-template: "a b";
        grid-template-columns: .35fr .15fr .75fr;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
        input {
          max-width: 5rem;
        }
      }

      img {
        cursor: pointer;
        width: 23px;
        padding: 0.2rem;
        height: 1.4rem;
        display: inline-block;
      }
      button {
        padding-left: 0.2rem;
        padding-right: 0.05rem;
        border-radius: 1rem;
        border: none;
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }
    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }
    .caixa-form-faixas {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 0;
      width: -webkit-fill-available;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: solid 0.1rem;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: solid 0.1rem grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-faixas {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
  @media screen and (min-width: "891px") and (max-width: "1065px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }

    .divFaixas {
      display: grid;
      grid-template: "a b c";
      grid-template-columns: .03fr .7fr .297fr;
      align-items: center;
      gap: 1.5rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      .limite {
        display: grid;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      img {
        cursor: pointer;
        width: 23px;
        padding: 0.2rem;
        height: 1.4rem;
        display: inline-block;
      }
      button {
        padding-left: 0.2rem;
        padding-right: 0.05rem;
        border-radius: 1rem;
        border: none;
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }

    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }

    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }

    .caixa-form-faixas {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 1rem 0 .5rem;
      width: auto;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      @media screen and (max-width:"699px") {
        .caixa-form-faixas {
          background-color: #efefef;
          border-radius: 0.2rem;
          margin: 1rem 1rem 0 .5rem;

          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }

          input:disabled {
            background: white;
          }

          input[type="file"] {
            display: none;
          }

          .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
          }

          .custom-file-upload-disable {
            border-radius: 0.5rem;
            border: solid 0.1rem grey;
            display: inline-block;
            padding: 6px 12px;
            margin: .3rem .4rem 0 .4rem;
            background-color: white;
            color: grey;
          }

          .item-faixas {
            display: grid;
            margin: 1rem 0;
            width: -webkit-fill-available;

            /* margin: 1rem 0; */
            label {
              display: inline;

              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
            }

            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }

            a {
              color: #ffa600;
            }

            a:hover {
              color: #e49503;
            }

            .aaa {
              color: #efefef;
            }
          }
        }
      }

      @media screen and (min-width:"700px") {
        .custom-file-upload {
          border-radius: 0.5rem;
          border: solid 0.1rem;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          cursor: pointer;
        }

        .custom-file-upload-disable {
          border-radius: 0.5rem;
          border: solid 0.1rem grey;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          background-color: white;
          color: grey;
        }
      }

      .item-faixas {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          display: inline;

          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "890px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .divFaixas {
      display: grid;
      align-items: center;
      gap: 1.5rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      .limite {
        display: grid;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      img {
        cursor: pointer;
        width: 23px;
        padding: 0.2rem;
        height: 1.4rem;
        display: inline-block;
      }
      button {
        width: fit-content;
        padding-left: 0.2rem;
        padding-right: 0.05rem;
        border-radius: 1rem;
        border: none;
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }
    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }
    .caixa-form-faixas {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 1rem 0 .5rem;
      width: -webkit-fill-available;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: solid 0.1rem;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: solid 0.1rem grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-faixas {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
</style>
