import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class CargoApi {
  cancelTokens = {
    getCargo: null,
    getCargos: null,
    setCargo: null,
    destroyCargo: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async destroyCargo(cargoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyCargo = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos/${cargoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.destroyCargo = null;
    return data;
  }

  async setCargo(cargo) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setCargo = source;

    if (cargo.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos/${cargo.id}`,
        {
          cancelToken: source.token,
          ...cargo,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setCargo = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos`,
        {
          cancelToken: source.token,
          ...cargo,
        }
        //
      );

      this.cancelTokens.setCargo = null;
      return data;
    }
  }

  async getCargos({ page = 1, qtd_por_pagina, nome }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargos = null;

    return data;
  }

  async getCargo(cargoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargo = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos/${cargoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargo = null;

    return data;
  }
}

export default CargoApi;
