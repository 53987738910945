<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar class="voltar" nomeRota="Servidor" />
      <h1>Visualizar Servidor(a)</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-3">
              <p class="item">Nome:
                <span>{{ servidor.nome }}</span>
              </p>
              <p class="item">CPF:
                <span>{{ formatarCPF(servidor.cpf) }}</span>
              </p>
            </div>
            <div class="form-cargos item">
              <p>Cargo(s):</p>
              <div class="flexbox-1">
                <template v-for="cargo in servidor.cargos">
                  <template>
                    <fieldset :key="cargo.id" class="cargos">
                      <div class="nome">
                        <p>Nome do cargo: </p>
                        <span>{{ cargo.nome }}</span>
                      </div>
                    </fieldset>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
        <div class="div-center">
          <div :id="servidor.id">
            <a :href="'/servidores/' + servidor.id + '/edit'">
              <button id="botao-editar">
                Editar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="servidor.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="servidor.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="servidor.created_at"
        :updated_at="servidor.updated_at"
      />
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import ServidorApi from "../../services/ServidorApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirServidor",
    props: {
      servidor: Object,
      temPermissao: Object,
    },
    data() {
      return {
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        hasError: false,
        r: false,

        dadosServidores: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      
      }
    },
    created() {
      this.ServidorApi = new ServidorApi();
    },
    components: {
      BotaoVoltar,
      Footer,
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
      formatarCPF(cpf) {
        // Lógica para formatar o CPF (exemplo: 123.456.789-01)
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: 1101px) {
    .fora {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f8f8;
    margin: 0;
    padding: 1.25rem;

    .topo-header {
      display: flex;
      align-items: center;

      img {
        height: 1.5rem;
      }

      h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.8rem;
      }
    }
    .form {
      .div-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      fieldset {
        background-color: #dbdbdb;
        padding: 1rem 2rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .dados {
          border-radius: 0.7rem;
          padding-top: 1%;
          display: grid;

          p {
            font-family: "Montserrat-Medium";
            font-size: 1.1rem;
            font-weight: bold;
            display: block;
            width: fit-content;
          }

          span {
            width: fit-content;
            display: inline-block;
            margin-left: .3rem;
            font-size: 1.1rem;
            font-weight: normal;
          }

          .flexbox-1 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
          }
          .flexbox-3 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 1rem;
          }
        }
      }

      .form-cargos {
        margin: 0 0 2rem 0;
        fieldset.cargos {
          background-color: #dbdbdb;
          padding: 1rem 1rem;
          border-radius: 1rem;
          box-shadow: #000000 0px 5px 15px;
          margin: 0rem 2rem;
          text-align: center;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 2rem 0;
            }

            span {
              font-size: 1.1rem;
              font-weight: normal;
            }

            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }

            .flexbox-2 {
              display: grid;
              grid-template-columns: .3fr 1fr .3fr;
              gap: 2rem;
            }
          }
        }
      }
    }
    .bt-editar {
      text-align: center;
      margin: 1.5rem 0 0 0;

      button {
        background-color: #005516;
        color: white;
        border: none;
        font-size: 1rem;
        padding: 0.4rem 2rem;
        border-radius: 2rem;
        margin: 0.2rem;
      }

      label {
        display: none;
        padding-left: 10px;
        width: 10px;
      }

      #botao-editar:hover {
        background-color: #4CAF50;
      }

      #botao-editar-tab:hover {
        background-color: #4CAF50;
      }
    }
    .footer {
      display: block;
      width: fit-content;
      height: 2rem;
      color: #000000;
      margin: 0 0 0 3rem;

      .criado-por {
        display: block;
        font-size: .9rem;
        /* Alterado as seguintes fontes para padronizar o rodapé de informações */
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }

      .atualizado-por {
        margin: 1rem 0 0 0;
        display: block;
        font-size: .9rem;
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }
    }
  }
  }

  @media screen and (min-width: 700px) and (max-width: 1100px){
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 2rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            gap: 1rem;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: block;
              width: fit-content;
            }

            span {
              width: fit-content;
              display: inline-block;
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .flexbox-1 {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
            .flexbox-3 {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
          }
        }

        .form-cargos {
          margin: 0 0 2rem 0;
          div.flexbox-1 {
            display: grid;
            grid-template-columns: .5fr .5fr;
            gap: 2rem;
          }
          fieldset.cargos {
            background-color: #dbdbdb;
            padding: 1rem 1rem;
            border-radius: 1rem;
            box-shadow: #000000 0px 5px 15px;
            margin: 0rem 2rem;
            text-align: center;

            .dados {
              border-radius: 0.7rem;
              padding-top: 1%;
              display: grid;

              p {
                font-family: "Montserrat-Medium";
                font-size: 1.1rem;
                font-weight: bold;
                display: inline-block;
                width: fit-content;
                margin: 0 0 2rem 0;
              }

              span {
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: 2rem;
        color: #000000;
        margin: 0 0 0 3rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
  }

  @media screen and (min-width: 60px) and (max-width: 699px){
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 2rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            gap: 1rem;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: block;
              width: fit-content;
            }

            span {
              width: fit-content;
              display: inline-block;
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .flexbox-1 {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
            .flexbox-3 {
              display: flex;
              flex-direction: column;
              gap: 1rem;
            }
          }
        }

        .form-cargos {
          margin: 0 0 2rem 0;
          .flexbox-1 {
            display: flex;
            flex-direction: column;
            gap: 2rem;
          }
          fieldset.cargos {
            background-color: #dbdbdb;
            padding: 1rem 1rem;
            border-radius: 1rem;
            box-shadow: #000000 0px 5px 15px;
            margin: 0rem 2rem;
            text-align: center;

            .dados {
              border-radius: 0.7rem;
              padding-top: 1%;
              display: grid;

              p {
                font-family: "Montserrat-Medium";
                font-size: 1.1rem;
                font-weight: bold;
                display: inline-block;
                width: fit-content;
                margin: 0 0 2rem 0;
              }

              span {
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: 2rem;
        color: #000000;
        margin: 0 0 0 3rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }
  }
</style>