<template>
    <div>
        <StatusForm :status="null" />
    </div>
</template>

<script>
    import StatusForm from "../../components/status/StatusForm.vue"

    export default {
        name: "StausNew",
        data() {
            return {};
        },
        components: {
            StatusForm,
        },
    };
</script>

<style lang="scss" scoped></style>