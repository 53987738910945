<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar class="voltar" nomeRota="Onus" />
      <h1>Visualizar Ônus</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-1">
              <p>Nome:
                <span>{{ onus.nome }}</span>
              </p>
            </div>
            <div class="flexbox-1">
              <p>Ativo:
                  <span v-if="onus.ativo == true">Sim</span>
                  <span v-else>Não</span>
              </p>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
        <div class="div-center">
          <div :id="onus.id">
            <a :href="'/onus/' + onus.id + '/edit'">
              <button id="botao-editar">
                Editar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="onus.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="onus.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="onus.created_at"
        :updated_at="onus.updated_at"
      />
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import OnusApi from "../../services/OnusApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirOnus",
    props: {
      onus: Object,
      temPermissao: Object,
    },
    data() {
      return {
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        hasError: false,
        r: false,

        dadosOnus: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.OnusApi = new OnusApi();
    },
    components: {
      BotaoVoltar,
      Footer,
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .fora {
    width: 100%;
    min-height: 100vh;
    background-color: #f8f8f8;
    margin: 0;
    padding: 1.25rem;

    .topo-header {
      display: flex;
      align-items: center;

      img {
        height: 1.5rem;
      }

      h1 {
        font-family: "Montserrat-Bold";
        color: #ffa600;
        margin-left: 1rem;
        font-size: 1.8rem;
      }
    }
    .form {
      .div-center {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      fieldset {
        background-color: #dbdbdb;
        padding: 1rem 2rem;
        border-radius: 1rem;
        box-shadow: #005516 0px 5px 15px;

        .dados {
          border-radius: 0.7rem;
          padding-top: 1%;
          display: grid;

          p {
            font-family: "Montserrat-Medium";
            font-size: 1rem;
            font-weight: bold;
            display: inline-block;
            width: fit-content;
          }

          span {
            display: inline-block;
            margin-left: .3rem;
            font-size: .9rem;
            font-weight: normal;
          }

          .flexbox-1 {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            gap: 1rem;
          }
        }
      }
    }
    .bt-editar {
      text-align: center;
      margin: 1.5rem 0 0 0;

      button {
        background-color: #005516;
        color: white;
        border: none;
        font-size: 1rem;
        padding: 0.4rem 2rem;
        border-radius: 2rem;
        margin: 0.2rem;
      }

      label {
        display: none;
        padding-left: 10px;
        width: 10px;
      }

      #botao-editar:hover {
        background-color: #4CAF50;
      }

      #botao-editar-tab:hover {
        background-color: #4CAF50;
      }
    }
    .footer {
      display: block;
      width: fit-content;
      height: 2rem;
      color: #000000;
      margin: 0 0 0 3rem;

      .criado-por {
        display: block;
        font-size: .9rem;
        /* Alterado as seguintes fontes para padronizar o rodapé de informações */
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }

      .atualizado-por {
        margin: 1rem 0 0 0;
        display: block;
        font-size: .9rem;
        font-family: "Montserrat-Medium";

        p {
          display: inline;
          /* font-weight: bold; */
          font-size: .9rem;
          font-family: "Montserrat-bold";
        }
      }
    }
  }

  .inline {
    display: inline-block;
  }
</style>