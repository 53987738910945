<template>
  <div class="fora">
    <div class="topo-header centro">
      <botao-voltar class="voltar" nomeRota="ConveniosCotasShow" />
      <h1>Visualizar Convênio</h1>
    </div>
    <div class="form centro">
      <div class="dados">
        <fieldset>
          <div class="dados">
            <div class="flexbox-1">
              <p class="item">Nome:
                <span>{{ convenioCota.nome }}</span>
              </p>
              <div v-if="convenioCota.ativo == true" class="item">
                <p>Ativo:
                  <span>Sim</span>
                </p>
              </div>
              <div v-else>
                <p>Ativo:                
                  <span>Não</span>
                </p>                  
              </div>
            </div>
            <p class="item">Órgão:
              <span>{{ convenioCota.nome_orgao_conveniado }}</span>
            </p>
            <div class="form-faixas item">
              <p>Faixas:</p>
                <div class="flexbox-1">
                  <template v-for="faixa in convenioCota.faixas_convenios">
                    <template>
                      <fieldset :key="faixa.id" class="faixas">
                        <div class="nome">
                          <p>Nome da faixa: </p>
                          <span>{{ faixa.nome }}</span>
                        </div>
                        <div class="limite">
                          <p>Limite: </p>
                          <span>{{ faixa.limite }}</span>
                        </div>
                      </fieldset>
                    </template>
                  </template>
                </div>
            </div>
            <p class="item">Observação:
              <span>{{ convenioCota.observacao }}</span>
            </p>
          </div>
        </fieldset>
      </div>
      <div class="bt-editar" v-if="temPermissao.admin || temPermissao.gpd">
        <div class="div-center">
          <div :id="convenioCota.id">
            <a :href="'/convenio_cota/' + convenioCota.id + '/edit'">
              <button id="botao-editar">
                Editar
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Footer
        :createdByLabel="'Criado por'"
        :criadoPor="convenioCota.criado_por"
        :lastUpdatedByLabel="'Última atualização feita por'"
        :atualizadoPor="convenioCota.atualizado_por"
        :emLabel="' em '"
        :SistemaLabel="'Sistema'"
        :created_at="convenioCota.created_at"
        :updated_at="convenioCota.updated_at"
      />
    </div>
  </div>
</template>

<script>
  import BotaoVoltar from "../BotaoVoltar.vue";
  import ConvenioCotaApi from "../../services/ConvenioCotaApi";
  import { parseISO, format } from "date-fns"
  import Footer from "../Footer.vue"

  export default {
    name: "ExibirConvenio",
    props: {
      convenioCota: Object,
      temPermissao: Object,
    },
    data() {
      return {
        count: null,
        salvando: false,
        erro: false,
        erroSalvar: false,
        r: false,

        dadosConvenio: {
          criado_por: null,
          atualizado_por: null,
          created_at: null,
          updated_at: null,
        }
      }
    },
    created() {
      this.convenioCotaApi = new ConvenioCotaApi();
    },
    methods: {
      formatarDataHora: function (value) {
        return format(parseISO(value), "dd/MM/yyyy") + " às " + format(parseISO(value), "HH:mm:ss.");
      },
    },
    components: {
      BotaoVoltar,
      Footer,
    },
  };
</script>

<style lang="scss" scoped>
  .flexbox-1 {
    display: grid;
    grid-template-columns: .5fr .5fr;
    gap: 2rem;
  }

  .flexbox-2 {
    display: grid;
    grid-template-columns: .3fr 1fr .3fr;
    gap: 2rem;
  }
  @media screen and (min-width: 1066px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.8rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 2rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }

            .flexbox-2 {
              display: grid;
              grid-template-columns: .3fr 1fr .3fr;
              gap: 2rem;
            }

            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;

              p {
                margin: 0 0 1rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }

        .form-faixas {
          margin: 0 0 2rem 0;
          fieldset.faixas {
            background-color: #dbdbdb;
            padding: 1rem 1rem;
            border-radius: 1rem;
            box-shadow: #000000 0px 5px 15px;
            margin: 0rem 2rem;

            .dados {
              border-radius: 0.7rem;
              padding-top: 1%;
              display: grid;

              p {
                font-family: "Montserrat-Medium";
                font-size: 1.1rem;
                font-weight: bold;
                display: inline-block;
                width: fit-content;
                margin: 0 0 2rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }

              .flexbox-1 {
                display: grid;
                grid-template-columns: .5fr .5fr;
                gap: 2rem;
              }

              .flexbox-2 {
                display: grid;
                grid-template-columns: .3fr 1fr .3fr;
                gap: 2rem;
              }

              .convenio {
                padding: 1rem;
                box-shadow: black 0px 5px 15px;
                margin: 0 1rem 2rem 1rem;

                p {
                  margin: 0 0 1rem 0;
                }

                span {
                  margin-left: .3rem;
                  font-size: 1.1rem;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: 2rem;
        color: #000000;
        margin: 0 0 0 3rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }

    .inline {
      display: inline-block;
    }
  }
  @media screen and (min-width: 665px) and (max-width: 1065px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            p {
              font-family: "Montserrat-Medium";
              font-size: 1.1rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 2rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: 1.1rem;
              font-weight: normal;
            }

            .flexbox-1 {
              display: grid;
              grid-template-columns: .5fr .5fr;
              gap: 2rem;
            }

            .flexbox-2 {
              display: grid;
              grid-template-columns: .3fr 1fr .3fr;
              gap: 2rem;
            }

            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;

              p {
                margin: 0 0 1rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }

        .form-faixas {
          margin: 0 0 2rem 0;
          fieldset.faixas {
            background-color: #dbdbdb;
            padding: 1rem 1rem;
            border-radius: 1rem;
            box-shadow: #000000 0px 5px 15px;
            margin: 0rem 1rem;

            .nome {
              p {
                font-family: "Montserrat-Medium";
                font-size: .9rem;
                font-weight: bold;
                display: inline-block;
                width: fit-content;
                margin: 0 0 .5rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: .9rem;
                font-weight: normal;
              }
            }
            .limite {
              p {
                font-family: "Montserrat-Medium";
                font-size: .9rem;
                font-weight: bold;
                display: inline-block;
                width: fit-content;
                margin: 0 0 .5rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: .9rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: fit-content;
        color: #000000;
        padding: 0;
        margin: 3rem 1rem 0 1rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }

    .inline {
      display: inline-block;
    }
  }
  @media screen and (min-width: 60px) and (max-width: 664px) {
    .fora {
      width: 100%;
      min-height: 100vh;
      background-color: #f8f8f8;
      margin: 0;
      padding: 1.25rem;

      .topo-header {
        display: flex;
        align-items: center;

        img {
          height: 1.5rem;
        }

        h1 {
          font-family: "Montserrat-Bold";
          color: #ffa600;
          margin-left: 1rem;
          font-size: 1.5rem;
        }
      }
      .form {
        .div-center {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        fieldset {
          background-color: #dbdbdb;
          padding: 1rem 3rem;
          border-radius: 1rem;
          box-shadow: #005516 0px 5px 15px;

          .dados {
            border-radius: 0.7rem;
            padding-top: 1%;
            display: grid;

            .form-faixas {
              margin: 0 0 2rem 0;
              div.flexbox-1 {
                display: grid;
                grid-template-columns: none;
                gap: 2rem;
              }
              fieldset.faixas {
                background-color: #dbdbdb;
                padding: 1rem 1rem;
                border-radius: 1rem;
                box-shadow: #000000 0px 5px 15px;
                margin: 0rem 1rem;

                .nome {
                  p {
                    font-family: "Montserrat-Medium";
                    font-size: .9rem;
                    font-weight: bold;
                    display: inline-block;
                    width: fit-content;
                    margin: 0 0 .5rem 0;
                  }

                  span {
                    margin-left: .3rem;
                    font-size: .9rem;
                    font-weight: normal;
                  }
                }
                .limite {
                  p {
                    font-family: "Montserrat-Medium";
                    font-size: .9rem;
                    font-weight: bold;
                    display: inline-block;
                    width: fit-content;
                    margin: 0 0 .5rem 0;
                  }

                  span {
                    margin-left: .3rem;
                    font-size: .9rem;
                    font-weight: normal;
                  }
                }
              }
            }

            p {
              font-family: "Montserrat-Medium";
              font-size: .9rem;
              font-weight: bold;
              display: inline-block;
              width: fit-content;
              margin: 0 0 2rem 0;
            }

            span {
              margin-left: .3rem;
              font-size: 0.9rem;
              font-weight: normal;
            }

            .convenio {
              padding: 1rem;
              box-shadow: black 0px 5px 15px;
              margin: 0 1rem 2rem 1rem;

              p {
                margin: 0 0 1rem 0;
              }

              span {
                margin-left: .3rem;
                font-size: 1.1rem;
                font-weight: normal;
              }
            }
          }
        }
      }
      .bt-editar {
        text-align: center;
        margin: 1.5rem 0 0 0;

        button {
          background-color: #005516;
          color: white;
          border: none;
          font-size: 1rem;
          padding: 0.4rem 2rem;
          border-radius: 2rem;
          margin: 0.2rem;
        }

        label {
          display: none;
          padding-left: 10px;
          width: 10px;
        }

        #botao-editar:hover {
          background-color: #4CAF50;
        }

        #botao-editar-tab:hover {
          background-color: #4CAF50;
        }
      }
      .footer {
        display: block;
        width: fit-content;
        height: fit-content;
        color: #000000;
        padding: 0;
        margin: 3rem 1rem 0 1rem;

        .criado-por {
          display: block;
          font-size: .9rem;
          /* Alterado as seguintes fontes para padronizar o rodapé de informações */
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }

        .atualizado-por {
          margin: 1rem 0 0 0;
          display: block;
          font-size: .9rem;
          font-family: "Montserrat-Medium";

          p {
            display: inline;
            /* font-weight: bold; */
            font-size: .9rem;
            font-family: "Montserrat-bold";
          }
        }
      }
    }

    .inline {
      display: inline-block;
    }
  }
</style>