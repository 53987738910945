import axios from "axios";
import axiosInstance from "./axiosInstance";

class CargoServidorApi {
  cancelTokens = {
    getCargoServidor: null,
    getCargosServidor: null,
    setCargoServidor: null,
    destroyCargosServidores: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCargoServidor(cargoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargoServidor = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos_servidores/getCargoServidor/${cargoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargoServidor = null;

    return data;
  }

  async getCargosServidor(servidorId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargosServidor = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos_servidores/cargos_servidor/${servidorId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargosServidor = null;

    return data;
  }

  async setCargoServidor(cargoServidor) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setCargoServidor = source;

    if (cargoServidor.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos_servidores/${cargoServidor.id}`,
        {
          cancelToken: source.token,
          ...cargoServidor,
        }
      );

      this.cancelTokens.setCargoServidor = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos_servidores`,
        {
          cancelToken: source.token,
          ...cargoServidor,
        }
        //
      );

      this.cancelTokens.setCargoServidor = null;

      return data;
    }
  }

  async destroyCargosServidores(cargoId, servidorId) {

    let cargoServidor = await this.getCargoServidor(cargoId);

    for(const cargo of cargoServidor) {
      if(cargo.servidor_id === servidorId) {
        await axiosInstance.delete(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/cargos_servidores/${cargo.id}`,
        );
      }
    }
  }
}

export default CargoServidorApi;
