<template>
  <div class="resultados">
    <MsgErroFormularios v-if="erro && msgErros" :mensagens="msgErros" />
    <table>
      <thead>
        <tr>
          <th>Nome</th>
          <th>Código</th>
          <th>Ativo</th>
          <th>Ações</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="status in statusFormat">
          <template>
            <tr :key="status.id">
              <td data-label="Nome:">
                <span>{{ status.nome }}</span>
              </td>
              <td data-label="Código:">
                <span>{{ status.codigo }}</span>
              </td>
              <td data-label="Status ativo:">
                <span v-if="status.ativo == true">Sim</span>
                <span v-else>Não</span>
              </td>
              <td class="coluna-acoes-tabela">
                <!-- Visualizar -->
                <div class="icone-tabela">
                  <router-link :to="{
                    name: 'StatusShow',
                    params: { id: status.id },
                  }">
                    <img src="../../assets/imagens/icon-view.svg" alt="Visualizar" title="Visualizar" />
                  </router-link>
                </div>
              </td>
            </tr>
          </template>
        </template>

        <tr v-if="!statuses || statuses == 0" class="sem-resultados">
          <p><b>Nenhum Resultado Encontrado</b></p>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { parseISO, format } from "date-fns"
  import MsgErroFormularios from "../MsgErroFormularios";
  import StatusApi from "../../services/StatusApi";

  export default {
    name: "TabelaResultadosStatus",
    props: {
      statuses: Array,
      status: Object,
    },
    components: {
      MsgErroFormularios,
    },
    created() {
      this.statusApi = new StatusApi();
    },
    data() {
      return {
        msgErros: {
          status: '',
        },
        erro: false,
        statusFormat: this.statuses.map((v) => {
          return {
            ...v,
          };
        }),
      };
    },
    methods: {
      formatarData: function (value) {
        if (value == null) {
          return;
        }
        return format(parseISO(value), "dd/MM/yyyy");
      },
      formatarDataParaComparacao(value) {
        let newValue = new Date(value);
        let timeToCompare = newValue.getTime();
        return timeToCompare;
      }
    },
  };
</script>

<style lang="scss" scoped>
  @media screen and (min-width: "1066px") {
    .modal-mask {
      min-width: 50vw;
    }
    .resultados {
      width: 100%;
      height: max-content;
      margin: 0;

      table {
        border-collapse: collapse;
        width: 100%;
        a {
          cursor: pointer;
          color: #375a3a;
        }
        thead {
          border-bottom: solid #484344 1px;
          color: #0a4a22;
          th {
            padding: .5rem .7rem;
            text-align: center;
            background-color: #edf2ee;
            border-right: 1px solid #484344;
            font-size: .9rem;
            font-weight: bold;
          }
          th:last-child {
            border-right: none;
          }
        }
        tbody {
          tr {
            margin: 1rem;
            text-align: left;
            td {
              padding: 1rem .15rem .5rem 1rem;
              vertical-align: middle;
              font-size: 1rem;
            }
            td:nth-child(1) {
              min-width: 12rem;
            }
            td:nth-child(2) {
              padding: 1rem;
              text-align: center;
            }
            td:nth-child(3) {
              text-align: center;
            }
            td:nth-child(4) {
              text-align: center;
            }
            td:nth-child(5) {
              text-align: justify;
              max-width: 50rem;
            }
          }
          tr:nth-child(4n + 1),
          tr:nth-child(4n + 2) {
            text-align: left;
          }
          .coluna-acoes-tabela {
            .icone-tabela {
              width: fit-content;
              display: inline-block;
              background-color: #fff;
              margin: 0 .3rem 0 .3rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            img {
              height: .9rem;
            }

            button {
              border: none;
              background-color: #fff;
            }
          }
          .sem-resultados {
            background-color: transparent !important;
            text-align: center;
            height: 5rem;
            color: rgb(73, 73, 73);
            font-style: oblique;
          }
          .modal-exclusao {
            visibility: hidden;
            position: fixed;
            z-index: 1000;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            display: table;
            transition: opacity 0.3s ease;

            .modal-mask {
              border-radius: 1rem;
              position: relative;
              z-index: 1000;
              top: 20vh;
              width: calc(40vw - 10rem);
              height: fit-content;
              background-color: #f2f2f2;
              transition: opacity 0.3s ease;
              margin: 0 auto;
            }
            .modal-container {
              height: 100%;
              padding: .7rem;
              position: relative;
              overflow-y: auto;
              overflow-x: auto;
              font-family: "Montserrat-Medium";

              .modal-header {
                height: fit-content;
                width: fit-content;
                margin: .5rem auto .3rem auto;

                h2 {
                  font-size: 1.3rem;
                  font-weight: bold;
                }
              }
              .modal-body {
                border: 1px solid gray;
                border-radius: 1rem;
                text-align: -webkit-center;

                p {
                  font-size: 1rem;
                  font-weight: bold;
                  margin-top: .3rem;
                  margin-bottom: .5rem;
                  margin-left: 1rem;
                  color: #005516;
                }
                .dados {
                  font-weight: bold;
                  font-size: 1rem;
                  text-align-last: left;
                  margin: 0 0 .5rem 1rem;

                  span {
                    font-weight: normal;
                    font-size: .9rem;
                    margin-left: .3rem;
                  }
                }
              }
              .modal-footer {
                margin: .7rem auto 0rem auto;
                place-content: space-evenly;
                font-size: 1rem;

                .btn-confirmar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #005516;
                  color: #f2f2f2;
                }
                .btn-cancelar-exclusao {
                  border: none;
                  border-radius: .5rem;
                  width: 30%;
                  height: 2.2rem;
                  background-color: #bd0000;
                  color: #f2f2f2;
                }
              }
            }
          }
        }
      }
      td {
        img {
          height: 1.5rem;
          margin: 0rem 0.5rem 0rem 0.5rem;
        }
      }
    }
  }
  @media screen and (min-width: "570px") and (max-width: "1065px") {
    .modal-mask {
      min-width: 60vw;
    }
    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }
    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;
          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;
            .icone-tabela {
              margin: 0rem calc(22vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .7rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .9rem;
              margin: 0;
            }
            button {
              border: none;
            }
          }
        }
        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }
          td:nth-child(2) {
            text-align: left !important;
          }
          td:nth-child(3) {
            text-align: left !important;
          }
          td:nth-child(4) {
            text-align: left !important;
          }
          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }
        .sem-resultados {
          padding-top: 5rem;
        }
        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }
          span {
            font-size: .9rem;
          }
        }
        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }
        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }
          span {
            font-size: 1rem;
            display: block;
          }
        }
        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 20vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }
          .modal-container {
            height: 100%;
            padding: .7rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.3rem;
                font-weight: bold;
              }
            }
            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .5rem;
                margin-left: 1rem;
                color: #005516;
              }
              .dados {
                font-weight: bold;
                font-size: 1rem;
                text-align: initial;
                margin: 0 0 .5rem 1rem;

                span {
                  font-weight: normal;
                  font-size: .9rem;
                  margin-left: .3rem;
                }
              }
            }
            .modal-footer {
              margin: .7rem auto 0rem auto;
              place-content: space-evenly;
              font-size: 1rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #005516;
                color: #f2f2f2;
              }
              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 30%;
                height: 2.2rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "569px") {
    .modal-mask {
      min-width: 70vw;
    }
    .sem-resultados {
      background-color: transparent !important;
      text-align: center;
      height: 5rem;
      color: rgb(73, 73, 73);
      font-style: oblique;
    }
    .resultados {
      table {
        width: 100%;

        tbody {
          background-color: transparent;
          .coluna-acoes-tabela {
            width: fit-content;
            height: fit-content;
            margin: 1rem auto 0 auto;
            .icone-tabela {
              margin: 0rem calc(18vw + -1rem) 0rem 0rem;
              padding: .2rem;
            }

            .icone-tabela:hover {
              img {
                height: .6rem;
              }
            }

            .icone-tabela:last-child {
              margin: 0rem 0rem 0rem 0rem;
              padding: .2rem;
            }

            img {
              height: .8rem;
              margin: 0;
            }
            button {
              border: none;
            }
          }
        }
        thead {
          border: none;
          clip: rect(0 0 0 0);
          height: 1px;
          margin: -1px;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
        tr {
          margin: 1rem;
          display: block;
          margin-bottom: 1em;
          background-color: #dbdbdbba;
          padding: 1rem;
          border-radius: 2rem;

          td {
            padding: 0rem !important;
            min-height: 1.94rem;

            div {
              display: inline;
            }
          }
          td:nth-child(2) {
            text-align: left !important;
          }
          td:nth-child(3) {
            text-align: left !important;
          }
          td:nth-child(4) {
            text-align: left !important;
          }
          td:nth-child(5) {
            text-align: left !important;
            max-width: 50rem;
          }
        }
        .sem-resultados {
          padding-top: 5rem;
        }
        td {
          display: block;
          min-height: 1.3rem;
          margin: 0.3rem 0.5rem;
          font-size: 1.1rem;

          img {
            height: 1.5rem;
            margin: 0rem 1rem;
          }
          span {
            font-size: .9rem;
          }
        }
        td::before {
          content: attr(data-label);
          font-weight: bold;
          color: #0a4a22;
          font-family: "Poppins-Medium";
          margin-right: 0.5rem;
          font-size: 1.2rem;
        }
        .topo-card {
          border: 1px solid;
          border-radius: 1rem;
          box-shadow: #484344 1px 5px 15px;
          width: fit-content;
          background-color: #fff;
          font-family: "Poppins";
          text-align: center;
          margin: 0rem auto 2rem auto;
          font-weight: 600 !important;
          padding: 1rem 4rem !important;
          font-size: 1.2rem;
          color: #484344;

          a {
            color: white;
            text-decoration: none;
          }
          span {
            font-size: 1rem;
            display: block;
          }
        }
        .modal-exclusao {
          visibility: hidden;
          position: fixed;
          z-index: 1000;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.6);
          display: table;
          transition: opacity 0.3s ease;

          .modal-mask {
            border-radius: 1rem;
            position: relative;
            z-index: 1000;
            top: 30vh;
            width: calc(60vw - 10rem);
            height: fit-content;
            background-color: #f2f2f2;
            transition: opacity 0.3s ease;
            margin: 0 auto;
          }
          .modal-container {
            height: 100%;
            padding: .3rem;
            position: relative;
            overflow-y: auto;
            overflow-x: auto;
            font-family: "Montserrat-Medium";

            .modal-header {
              height: fit-content;
              width: fit-content;
              margin: .5rem auto .3rem auto;

              h2 {
                font-size: 1.1rem;
                font-weight: bold;
              }
            }
            .modal-body {
              border: 1px solid gray;
              border-radius: 1rem;
              text-align: -webkit-center;

              p {
                font-size: 1rem;
                font-weight: bold;
                margin-top: .3rem;
                margin-bottom: .2rem;
                margin-left: 1rem;
                color: #005516;
              }
              .dados {
                font-weight: bold;
                font-size: .9rem;
                text-align: initial;
                margin: 0 0 .5rem .3rem;

                span {
                  font-weight: normal;
                  font-size: .8rem;
                  margin-left: .3rem;
                }
              }
            }
            .modal-footer {
              margin: .5rem auto 0rem auto;
              place-content: space-evenly;
              font-size: .9rem;

              .btn-confirmar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #005516;
                color: #f2f2f2;
              }
              .btn-cancelar-exclusao {
                border: none;
                border-radius: .5rem;
                width: 25%;
                height: 1.8rem;
                background-color: #bd0000;
                color: #f2f2f2;
              }
            }
          }
        }
      }
    }
  }
</style>