import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class ServidorApi {
  cancelTokens = {
    getServidores: null,
    getServidor: null,
    setServidor: null,
    destroyServidor: null,
    getDadosRhNet: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getServidores({ page = 1, qtd_por_pagina, cpf, nome, cargo}) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      cpf,
      nome,
      cargo
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getServidores = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getServidores = null;
    return data;
  }

  async getServidor(servidorId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getServidor = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/${servidorId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getServidor = null;

    return data;
  }

  async destroyServidor(servidorId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyServidor = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/${servidorId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getServidor = null;
    return data;
  }

  async setServidor(servidor) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setServidor = source;

    if (servidor.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/${servidor.id}`,
        {
          cancelToken: source.token,
          ...servidor,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setServidor = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores`,
        {
          cancelToken: source.token,
          ...servidor,
        }
        //
      );

      this.cancelTokens.setServidor = null;
      return data;
    }
  }

  async getDadosRhNet(cpf) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getDadosRhNet = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/buscar_dados_rh_net.json?cpf=${cpf}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getDadosRhNet = null;
    return data;
  }

  async getCargos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/cargos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargos = null;

    return data;
  }

  async getCargosServidor(idServidor) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargosServidor = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/servidores/cargos_servidor/${idServidor}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargosServidor = null;

    return data;
  }
}

export default ServidorApi;
