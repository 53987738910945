import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class OrgaoApi {
  cancelTokens = {
    getOrgao: null,
    getOrgaos: null,
    getStatus: null,
    getOrgaoscp: null,
    getOrgaosPesquisa: null,
    getTipos: null,
    setOrgao: null,
    destroyOrgao: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async destroyOrgao(orgaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyOrgao = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/${orgaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgao = null;
    return data;
  }

  async setOrgao(orgao) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setOrgao = source;

    if (orgao.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/${orgao.id}`,
        {
          cancelToken: source.token,
          ...orgao,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setOrgao = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos`,
        {
          cancelToken: source.token,
          ...orgao,
        }
        //
      );

      this.cancelTokens.setOrgao = null;
      return data;
    }
  }

  async getOrgaos({ page = 1, qtd_por_pagina, nome, sigla, esfera, poder, ufs_id }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      sigla,
      esfera,
      poder,
      ufs_id,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaos = null;

    return data;
  }

  async getOrgao(orgaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/${orgaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgao = null;

    return data;
  }

  async getOrgaoscp() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaoscp = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/orgaoscp`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaoscp = null;

    return data;
  }

  async getOrgaosPesquisa() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/orgaosPesquisa`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaos = null;

    return data;
  }

  async getEsferas() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getEsferas = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/esferas`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getEsferas = null;

    return data;
  }
  
  async getUfs() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getUfs = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/ufs`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getUfs = null;

    return data;
  }
  
  async getPoderes() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getPoderes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/poderes`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getPoderes = null;

    return data;
  }

  /* async downloadAnexo(){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.downloadAnexo = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/download_anexo`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.downloadAnexo = null;

    return data;
  } */
}

export default OrgaoApi;
