<template>
    <div>
        <OnusForm :onus="null" />
    </div>
</template>

<script>
    import OnusForm from "../../components/onus/OnusForm.vue"

    export default {
        name: "OnusNew",
        data() {
            return {};
        },
        components: {
            OnusForm,
        },
    };
</script>

<style lang="scss" scoped></style>