<template>
    <div>
        <CargoForm :cargo="null" />
    </div>
</template>

<script>
    import CargoForm from "../../components/cargos/CargoForm.vue"

    export default {
        name: "CargoNew",
        data() {
            return {};
        },
        components: {
            CargoForm,
        },
    };
</script>

<style lang="scss" scoped></style>