<template>
    <div>
        <transition name="modal" v-if="carregando">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-container">
                        <img src="../../assets/imagens/loadingSpinner.gif" alt="Roda de carregamento girando">
                        <label>Aguarde. Carregando.</label>
                    </div>
                </div>
            </div>
        </transition>
        <div v-else>
            <div v-if="erro">
                <ErroResultados></ErroResultados>
            </div>
            <template v-if="!erro">
                <CessaoForm :cessao="null" />
            </template>
        </div>
    </div>
</template>

<script>
    import CessaoApi from '../../services/CessaoApi';
    import CessaoForm from '../../components/cessoes/CessaoForm.vue';
    import ErroResultados from "../../components/ErroResultados";

    export default {
        name: "CessaoNew",
        data() {
            return {
                carregando: true,
                erro: false,
                servidores: [],
                orgaosConveniados: [],
                onus: [],
                status: [],
            };
        },
        components: {
            ErroResultados,
            CessaoForm,
        },
        created() {
            this.cessaoApi = new CessaoApi();
            this.carregarValores();
        },
        methods: {
            async carregarValores() {
                try {
                    this.carregando = true;
                    this.erro = false;

                    this.erro = false

                    const dadosServidores = await this.cessaoApi.getServidores();
                    this.servidores = dadosServidores
                    
                    const dadosOrgaosConveniados = await this.cessaoApi.getOrgaosConveniados();
                    this.orgaosConveniados = dadosOrgaosConveniados

                    const dadosOnus = await this.cessaoApi.getOnus();
                    this.onus = dadosOnus

                    const dadosStatus = await this.cessaoApi.getStatus();
                    this.status = dadosStatus

                    this.carregando = false;
                } catch (err) {
                    if (this.cessaoApi.isCancel(err)) {
                        return;
                    }
                    console.log("carregarValores", err);

                    this.carregando = false;
                    this.erro = true;
                    
                    this.servidores = [];
                    this.orgaosConveniados = [];
                    this.onus = [];
                    this.status = [];
                }
            },
        }
    };
</script>

<style lang="scss" scoped>
    .modal-mask {
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        display: table;
        transition: opacity 0.3s ease;
    }
    .modal-wrapper {
        display: table-cell;
        vertical-align: middle;
    }
    .modal-container {
        width: fit-content;
        height: 0px auto;
        margin: 0px auto;
        position: relative;
        overflow-y: auto;
        overflow-x: auto;
        font-size: 1.5rem;
        font-family: "Montserrat-Medium";

        img {
            width: 2rem;
            height: 2rem;
            margin-right: 1.6rem;
        }

        label {
            font-size: 1.3rem;
            color: rgba(255, 255, 255, 0.556);
            display: inline;
            position: relative;
            top: 0rem;
        }
    }
</style>