import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class CessaoApi {
  cancelTokens = {
    getCessoes: null,
    getCessao: null,
    setCessao: null,
    getServidores: null,
    getOrgaosConveniados: null,
    getConvenios: null,
    getFaixasConvenios: null,
    getCessoesData: null,
    getOnus: null,
    getStatus: null,
    getCargos: null,
    destroyCessoes: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCessoes({
    page = 1,
    qtd_por_pagina,
    nome_servidor,
    cpf,
    orgao_origem_id,
    orgao_destino_id,
    data_inicio,
    data_termino,
    possuiConvenio,
    possuiFc,
    numero_processo_sei,
    convenio_id,
    status_id,
    cessoes,
  }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome_servidor,
      cpf,
      orgao_origem_id,
      orgao_destino_id,
      data_inicio,
      data_termino,
      possuiConvenio,
      possuiFc,
      numero_processo_sei,
      convenio_id,
      status_id,
      cessoes,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessoes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessoes = null;
    return data;
  }

  async getCessao(cessaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/${cessaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessao = null;

    return data;
  }

  async setCessao(cessao) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setCessao = source;

    if (cessao.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/${cessao.id}`,
        {
          cancelToken: source.token,
          ...cessao,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setCessao = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes`,
        {
          cancelToken: source.token,
          ...cessao,
        }
        //
      );

      this.cancelTokens.setCessao = null;

      return data;
    }
  }

  async getServidores() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getServidores = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/servidores`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getServidores = null;

    return data;
  }
  /*async consultarProcedimento() {
    try {
      const response = await axios.get('/seu_controller/consultar_procedimento', {
        params: {
          protocolo_procedimento: 'valor_protocolo_procedimento'
        }
      });

      this.linkAcesso = response.data.link_acesso;
    } catch (error) {
      console.error('Erro ao consultar procedimento:', error);
    }
    return data;
  }*/
  async getOrgaosConveniados() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaosConveniados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/orgaos_conveniados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaosConveniados = null;

    return data;
  }

  async getConvenios() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getConvenios = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/convenios`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConvenios = null;

    return data;
  }

  async getFaixasConvenios() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getFaixasConvenios = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/faixasConvenios`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getFaixasConvenios = null;

    return data;
  }

  async getCessoesData() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessoesData = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/cessoesData`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessoesData = null;

    return data;
  }

  async getOnus() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOnus = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/onus`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOnus = null;

    return data;
  }

  async getStatus() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getStatus = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/status`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getStatus = null;

    return data;
  }

  async getCargos() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCargos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/cargos`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCargos = null;

    return data;
  }

  async getProcedimentoSei(numeroProcessoSei) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getProcedimentoSei = source;

    const { data } = await axiosInstance.post(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/consultar_procedimento_sei`,
      {
        numeroProcessoSei: numeroProcessoSei
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getProcedimentoSei = null;

    return data;
  }

  async getAndamentosSei(numeroProcessoSei) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getAndamentosSei = source;

    const { data } = await axiosInstance.post(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/listar_andamentos_sei`,
      {
        numeroProcessoSei: numeroProcessoSei
      },
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getAndamentosSei = null;
    return data;
  }

  async getOrgaoIdUsuarioLogado(usuarioLogadoOrgaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaoIdUsuarioLogado = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/getOrgaoIdUsuarioLogado`,
      {
        cancelToken: source.token,
        params: {
          usuarioLogadoOrgaoId: usuarioLogadoOrgaoId,
        },
      }
    );

    this.cancelTokens.getOrgaoIdUsuarioLogado = null;

    return data;
  }

  async destroyCessoes(cessaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyCessoes = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/${cessaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessoes = null;
    return data;
  }
}

export default CessaoApi;
