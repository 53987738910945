import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class HomeApi {
  cancelTokens = {
    getCessoes: null,
    getCessao: null,
    getOrgaos: null,
    getOrgao: null,
    //getDisposicoesData: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getCessoes(){
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessoes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/home`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessoes = null;
    return data;
  }

  async getCessao(cessaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getCessao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/cessoes/${cessaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getCessao = null;

    return data;
  }

  async getOrgaos({ page = 1, qtd_por_pagina, nome, esfera, poder, ufs_id }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      esfera,
      poder,
      ufs_id,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaos = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaos = null;

    return data;
  }

  async getOrgao(orgaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/orgaos/${orgaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgao = null;

    return data;
  }

  /*async getDisposicoes({
    page = 1,
    qtd_por_pagina,
    servidor_id,
    data_inicio,
    data_termino,
    disposicoes,
  }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      servidor_id,
      data_inicio,
      data_termino,
      disposicoes,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getDisposicoes = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/disposicoes?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getDisposicoes = null;
    return data;
  }

  async getDisposicao(disposicaoId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getDisposicao = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/disposicoes/${disposicaoId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getDisposicao = null;

    return data;
  }*/
}

export default HomeApi;
