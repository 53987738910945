<template>
    <div>
        <ConvenioCotaForm :convenioCota="null" />
    </div>
</template>

<script>
import ConvenioCotaForm from '../../components/convenios_cotas/ConvenioCotaForm.vue';

export default {
    name: "ConveniosCotasNew",
    data() {
        return {};
    },
    components: {
        ConvenioCotaForm,
    },
};
</script>

<style lang="scss" scoped></style>