import Home from "../views/home/Home"

import Cessao from "../views/cessoes/Cessao";
import CessaoNew from "../views/cessoes/CessaoNew";
import CessaoEdit from "../views/cessoes/CessaoEdit";
import CessaoShow from "../views/cessoes/CessaoShow";

//import Disposicao from "../views/disposicoes/Disposicao";
//import DisposicaoNew from "../views/disposicoes/DisposicaoNew";
//import DisposicaoEdit from "../views/disposicoes/DisposicaoEdit";
//import DisposicaoShow from "../views/disposicoes/DisposicaoShow";

import Cargo from "../views/cargos/Cargo";
import CargoShow from "../views/cargos/CargoShow";
import CargoEdit from "../views/cargos/CargoEdit";
import CargoNew from "../views/cargos/CargoNew";

import Servidor from "../views/servidores/Servidor";
import ServidorShow from "../views/servidores/ServidorShow";
import ServidorEdit from "../views/servidores/ServidorEdit";
import ServidorNew from "../views/servidores/ServidorNew";

import Orgao from "../views/orgaos/Orgao";
import OrgaoNew from "../views/orgaos/OrgaoNew";
import OrgaoShow from "../views/orgaos/OrgaoShow";
import OrgaoEdit from "../views/orgaos/OrgaoEdit";

import ConveniosCotas from "../views/convenios_cotas/ConveniosCotas";
import ConveniosCotasNew from "../views/convenios_cotas/ConveniosCotasNew";
import ConveniosCotasShow from "../views/convenios_cotas/ConveniosCotasShow";
import ConveniosCotasEdit from "../views/convenios_cotas/ConveniosCotasEdit";

import Onus from "../views/onus/Onus";
import OnusNew from "../views/onus/OnusNew";
import OnusShow from "../views/onus/OnusShow";
import OnusEdit from "../views/onus/OnusEdit";

import Status from "../views/status/Status";
import StatusNew from "../views/status/StatusNew";
import StatusShow from "../views/status/StatusShow";
import StatusEdit from "../views/status/StatusEdit";

import PainelBI from "../views/paineis_bi/PainelBI";

import NaoEncontrado from "../views/NaoEncontrado";

//raiz indica se é uma rota que pode ser a inicial para o usuario que logar (normalmente vao ser so os indexes de cada crud)
//colocar as rotas em ordem de prioridade para login (as mais no topo vao ser a tela inicial do usuario se ele tiver permissao)

export default [
  {
    name: "Home",
    path: "/home",
    component: Home,
    meta: {
      raiz: true,
      requerPermissao: true,
      permissoes: ["home_index_show"],
      label: "Home",      
    },
  },
  {
    name: "Cessao",
    path: "/cessoes",
    component: Cessao,
    meta: {
      raiz: true,
      requerPermissao: true,
      permissoes: ["cessoes_index_show"],
      label: "Cessões",      
    },
  },
  {
    name: "CessaoNew",
    path: "/cessoes/new",
    component: CessaoNew,
    meta: {
      requerPermissao: true,
      permissoes: ["cessoes_new_edit_delete"],
    },
  },
  {
    name: "CessaoShow",
    path: "/cessoes/:id",
    component: CessaoShow,
    meta: {
      requerPermissao: true,
      permissoes: ["cessoes_index_show"],
    },
  },
  {
    name: "CessaoEdit",
    path: "/cessoes/:id/edit",
    component: CessaoEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["cessoes_new_edit_delete"],
    },
  },
  {
    name: "Servidor",
    path: "/servidores",
    component: Servidor,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["servidores_index_show"],
      label: "Servidores",         
    },
  },
  {
    name: "ServidorNew",
    path: "/servidores/new",
    component: ServidorNew,
    meta: {
      requerPermissao: true,
      permissoes: ["servidores_new_edit_delete"],
    },
  },
  {
    name: "ServidorShow",
    path: "/servidores/:id",
    component: ServidorShow,
    meta: {
      requerPermissao: true,
      permissoes: ["servidores_index_show"],
    },
  },
  {
    name: "ServidorEdit",
    path: "/servidores/:id/edit",
    component: ServidorEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["servidores_new_edit_delete"],
    },
  },
  {
    name: "Cargo",
    path: "/cargos",
    component: Cargo,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["cargos_index_show"],
      label: "Cargos",         
    },
  },
  {
    name: "CargoNew",
    path: "/cargos/new",
    component: CargoNew,
    meta: {
      requerPermissao: true,
      permissoes: ["cargos_new_edit_delete"],
    },
  },
  {
    name: "CargoShow",
    path: "/cargos/:id",
    component: CargoShow,
    meta: {
      requerPermissao: true,
      permissoes: ["cargos_index_show"],
    },
  },
  {
    name: "CargoEdit",
    path: "/cargos/:id/edit",
    component: CargoEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["cargos_new_edit_delete"],
    },
  },
  {
    name: "Orgao",
    path: "/orgao",
    component: Orgao,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["orgaos_index_show"],
      label: "Órgãos",      
    },
  },
  {
    name: "OrgaoNew",
    path: "/orgao/new",
    component: OrgaoNew,
    meta: {
      requerPermissao: true,
      permissoes: ["orgaos_new_edit_delete"],
    },
  },
  {
    name: "OrgaoShow",
    path: "/orgao/:id",
    component: OrgaoShow,
    meta: {
      requerPermissao: true,
      permissoes: ["orgaos_index_show"],
    },
  },
  {
    name: "OrgaoEdit",
    path: "/orgao/:id/edit",
    component: OrgaoEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["orgaos_new_edit_delete"],
    },
  },
  {
    name: "ConveniosCotas",
    path: "/convenio_cota",
    component: ConveniosCotas,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["convenios_index_show"],
      label: "Convênios",         
    },
  },
  {
    name: "ConveniosCotasNew",
    path: "/convenio_cota/new",
    component: ConveniosCotasNew,
    meta: {
      requerPermissao: true,
      permissoes: ["convenios_new_edit_delete"],
    },
  },
  {
    name: "ConveniosCotasShow",
    path: "/convenio_cota/:id",
    component: ConveniosCotasShow,
    meta: {
      requerPermissao: true,
      permissoes: ["convenios_index_show"],
    },
  },
  {
    name: "ConveniosCotasEdit",
    path: "/convenio_cota/:id/edit",
    component: ConveniosCotasEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["convenios_new_edit_delete"],
    },
  },
  {
    name: "Onus",
    path: "/onus",
    component: Onus,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["onus_index_show"],
      label: "Ônus",      
    },
  },
  {
    name: "OnusNew",
    path: "/onus/new",
    component: OnusNew,
    meta: {
      requerPermissao: true,
      permissoes: ["onus_new_edit_delete"],
    },
  },
  {
    name: "OnusShow",
    path: "/onus/:id",
    component: OnusShow,
    meta: {
      requerPermissao: true,
      permissoes: ["onus_index_show"],
    },
  },
  {
    name: "OnusEdit",
    path: "/onus/:id/edit",
    component: OnusEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["onus_new_edit_delete"],
    },
  },
  {
    name: "Status",
    path: "/status",
    component: Status,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["status_index_show"],
      label: "Status",      
    },
  },
  {
    name: "StatusNew",
    path: "/status/new",
    component: StatusNew,
    meta: {
      requerPermissao: true,
      permissoes: ["status_new_edit_delete"],
    },
  },
  {
    name: "StatusShow",
    path: "/status/:id",
    component: StatusShow,
    meta: {
      requerPermissao: true,
      permissoes: ["status_index_show"],
    },
  },
  {
    name: "StatusEdit",
    path: "/status/:id/edit",
    component: StatusEdit,
    meta: {
      requerPermissao: true,
      permissoes: ["status_new_edit_delete"],
    },
  },
  {
    name: "PainelBI",
    path: "/paineis_bi",
    component: PainelBI,
    meta: {
      raiz: true,      
      requerPermissao: true,
      permissoes: ["paineis_bi"],
      label: "Painéis BI",      
    },
  },
  {
    name: "NaoEncontrado",
    path: "/nao-encontrado",
    component: NaoEncontrado,
  },
  {
    path: "*",
    redirect: (to) => {
      return { name: "NaoEncontrado", query: { url: to.path } };
    },
  },
];