<template>
    <div>
        <ServidorForm :servidor="null" :cargosServidorForm="null" />
    </div>
</template>

<script>
    import ServidorForm from "../../components/servidores/ServidorForm.vue"

    export default {
        name: "ServidorNew",
        data() {
            return {};
        },
        components: {
            ServidorForm,
        },
    };
</script>

<style lang="scss" scoped></style>