<template>
  <div id='cargos'>
    <div class="itemTop">
      <label>Adicionar Cargos: </label>
      <div class="bt-incluir">
        <button type="button" @click="adicionarCargos()">
          <span>+</span> Adicionar
        </button>
      </div>
    </div>
    <template v-for="(cargos, i) in local">
      <template>
        <div :key="cargos.id">
          <template>
            <div class="caixa-form-cargos item-cargos">
              <!-- Editar -->
              <div class="divCargos" v-if="cargos.id">
                <button type="button" @click="removerCargo(i)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                </button>
                <div class="nome">
                  <label>{{ possuiAsterisco("Nome do cargo:") }}</label>
                  <!--<input type="text" placeholder="Digite o nome do cargo" v-model="cargos.nome" />-->
                  <v-select
                    class="vselect"
                    v-model="cargos.nome"  
                    id="selectedCargo" 
                    :options="paginated"
                    label="nome" 
                    placeholder="Digite o nome do cargo"
                    :filterable="false" 
                    @search="onSearch"
                    @input="atualizarCargo(i)"
                    disabled>
                    <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                    </li>
                  </v-select>
                </div>
              </div>

              <!-- Adicionar -->
              <div class="divCargos" v-else>
                <button type="button" @click="removerCargo(i)">
                  <img src="../../assets/imagens/icon-delete-preto.svg" alt="Remover" title="Remover" />
                </button>
                <div class="nome">
                  <label>{{ possuiAsterisco("Nome do cargo:") }}</label>
                  <!--<input type="text" placeholder="Digite o nome do cargo" v-model="cargos.nome"/>-->
                  <v-select
                    class="vselect"
                    v-model="selectedCargo[i]"  
                    id="selectedCargo" 
                    :options="paginated"
                    label="nome" 
                    placeholder="Digite o nome do cargo"
                    :filterable="false" 
                    @search="onSearch"
                    @input="atualizarCargo(i)">
                    <li slot="list-footer" class="pagination">
                        <button :disabled="!hasPrevPage" @click.prevent="offset -= limit">Anterior</button>
                        <button :disabled="!hasNextPage" @click.prevent="offset += limit">Próxima</button>
                    </li>
                  </v-select>
                </div>
              </div>
            </div>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import { cloneDeep, tap } from "lodash";
  import ServidorApi from "../../services/ServidorApi.js";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";

  export default {
    name: "MultiplosCargos",
    props: ["value"],
    components: {
      vSelect,
    },
    data() {
      return {
        nomeRotaAtual: this.$router.currentRoute.name,
        carregando: true,
        erro: false,
        nome: null,
        cargos: [],
        selectedCargo: [],
        search: '',
        offset: 0,
        limit: 10,
      }
    },
    computed: {
      local() {
        return this.value ? this.value : [];
      },
      filtered() {
        return this.cargos.filter((cargo) =>
          cargo.nome.toLowerCase().includes(this.search.toLowerCase())
        )
      },
      paginated() {
        return this.filtered.slice(this.offset, this.limit + this.offset)
      },
      hasNextPage() {
        const nextOffset = this.offset + this.limit
        return Boolean(
          this.filtered.slice(nextOffset, this.limit + nextOffset).length
        )
      },
      hasPrevPage() {
        const prevOffset = this.offset - this.limit
        return Boolean(
          this.filtered.slice(prevOffset, this.limit + prevOffset).length
        )
      },
    },
    created() {
      this.servidorApi = new ServidorApi();
      this.carregarCargos();
    },
    methods: {
      atualizarCargo(index) {
        // Atualize o valor do cargo no array local
        this.$set(this.local, index, this.selectedCargo[index]);
      },
      onSearch(query) {
        this.search = query
        this.offset = 0
      },
      async carregarCargos() {
        try {
          if (this.carregandoFiltro && !this.erroFiltro) return;

          this.erroFiltro = false

          const dadosCargos = await this.servidorApi.getCargos();
          this.cargos = dadosCargos

          if(this.nomeRotaAtual == 'ServidorEdit') {
            for(let i = 0; i < this.local.length; i++) {
              this.cargos.map((cargo) => {
                if(cargo.id === this.local[i].id) {
                  this.selectedCargo[i] = cargo;
                  return;
                }
              })
            }
          }
        } catch (err) {
          console.log("carregarCargos", err);
          this.carregandoFiltro = false;
          this.erroFiltro = true;
          this.cargos = [];
        }
      },
      adicionarCargos() {
        this.selectedCargo = [];
        this.$emit(
          "input",
          tap(cloneDeep(this.local), (v) =>
            v.push({
              id: null,
              nome: null,
            })
          )
        );
      },
      removerCargo(i) {
        let clone;
        clone = tap(cloneDeep(this.local), (v) => v.splice(i, 1));
        this.$emit("input", clone);
      },
      possuiAsterisco(texto) {
        return "* " + texto;
      },
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
  .mensagem {
    display: none;
    color: red;
  }
  p#mensagem.mensagem {
    margin: 0;
  }
  @media screen and (min-width: "1066px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .divCargos {
      display: grid;
      grid-template: "a b";
      grid-template-columns: .2fr 4fr;
      align-items: center;
      gap: 1rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        grid-template: "a b";
        grid-template-columns: .15fr .85fr;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      img {
        cursor: pointer;
        height: 1.5rem;
        display: inline-block;
      }
      button {
        border: none;
        width: fit-content;
        place-self: center;
      }
      button:hover {
        img {
          height: 1.3rem;
        }
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }
    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }
    .caixa-form-cargos {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 0;
      width: -webkit-fill-available;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: solid 0.1rem;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: solid 0.1rem grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-cargos {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
  @media screen and (min-width: "891px") and (max-width: "1065px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }

    .divCargos {
      display: grid;
      grid-template: "a b";
      grid-template-columns: .2fr 4fr;
      align-items: center;
      gap: 1rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        grid-template: "a b";
        grid-template-columns: .2fr .8fr;
        gap: 0rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      img {
        cursor: pointer;
        height: 1.3rem;
        display: inline-block;
      }
      button {
        border: none;
        width: fit-content;
        place-self: center;
      }
      button:hover {
        img {
          height: 1rem;
        }
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }

    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }

    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }

    .caixa-form-cargos {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 1rem 0 .5rem;
      width: auto;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      @media screen and (max-width:"699px") {
        .caixa-form-cargos {
          background-color: #efefef;
          border-radius: 0.2rem;
          margin: 1rem 1rem 0 .5rem;

          input {
            border-radius: 0.5rem;
            border: solid 0.1rem;
          }

          input:disabled {
            background: white;
          }

          input[type="file"] {
            display: none;
          }

          .custom-file-upload {
            border-radius: 0.5rem;
            border: solid 0.1rem;
            display: inline-block;
            padding: 6px 12px;
            margin-left: 0.4rem;
            margin-right: 0.4rem;
            cursor: pointer;
          }

          .custom-file-upload-disable {
            border-radius: 0.5rem;
            border: solid 0.1rem grey;
            display: inline-block;
            padding: 6px 12px;
            margin: .3rem .4rem 0 .4rem;
            background-color: white;
            color: grey;
          }

          .item-cargos {
            display: grid;
            margin: 1rem 0;
            width: -webkit-fill-available;

            /* margin: 1rem 0; */
            label {
              display: inline;

              font-family: "Montserrat-Medium";
              font-size: 0.9rem;
            }

            input {
              height: 2rem;
              font-family: "Montserrat-Medium";
              padding-left: 0.5rem;
              font-size: 0.9rem;
            }

            a {
              color: #ffa600;
            }

            a:hover {
              color: #e49503;
            }

            .aaa {
              color: #efefef;
            }
          }
        }
      }

      @media screen and (min-width:"700px") {
        .custom-file-upload {
          border-radius: 0.5rem;
          border: solid 0.1rem;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          cursor: pointer;
        }

        .custom-file-upload-disable {
          border-radius: 0.5rem;
          border: solid 0.1rem grey;
          display: inline-block;
          padding: 6px 12px;
          margin-left: 0.4rem;
          margin-right: 0.4rem;
          background-color: white;
          color: grey;
        }
      }

      .item-cargos {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          display: inline;

          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
  @media screen and (min-width: "60px") and (max-width: "890px") {
    .itemTop {
      display: inline;

      /* margin: 1rem 0; */
      label {
        display: inline;
        color: black;
        font-family: "Montserrat-Medium";
        font-size: 0.9rem;
      }

      .bt-incluir {
        display: inline;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        button {
          color: #005516;
          text-decoration: none;
          font-family: "Montserrat-Medium";
          font-size: 75%;
          border: #005516 solid 0.15rem;
          border-radius: 0.5rem;
          transition: 0.2s;
          padding: 0.1rem;
          margin-bottom: 0rem;
          margin-top: 0.2rem;

          span {
            font-family: "Montserrat-Bolder";
          }
        }

        button:hover {
          color: white;
          background-color: #005516;
        }
      }
    }
    .divCargos {
      display: grid;
      grid-template: "a b";
      grid-template-columns: .2fr 4fr;
      align-items: center;
      gap: 1rem;
      padding: 1rem .3rem;

      .nome {
        display: grid;
        gap: .5rem;
        align-items: center;
        label {
          margin: 0;
        }
      }

      img {
        cursor: pointer;
        height: 1.2rem;
        display: inline-block;
      }
      button {
        border: none;
        width: fit-content;
        place-self: center;
      }
      button:hover {
        img {
          height: 1rem;
        }
      }
      p {
        margin-bottom: 0%;
        margin-top: 1rem;
      }
    }
    .bt-desfazer {
      button {
        color: white;
        background-color: #ffa600;
        text-decoration: none;
        font-family: "Montserrat-Medium";
        font-size: 75%;
        border: #ffa600 solid 0.06rem;
        border-radius: 0.5rem;
        transition: 0.2s;
        padding: 0.3rem;
        margin-bottom: 0rem;
        margin-top: 1rem;
      }
    }
    .myTextArea {
      resize: vertical;
      font-size: 17px;
    }
    .caixa-form-cargos {
      background-color: #efefef;
      border-radius: 0.2rem;
      margin: 1rem 1rem 0 .5rem;
      width: -webkit-fill-available;

      input {
        border-radius: 0.5rem;
        border: solid 0.1rem;
      }

      input:disabled {
        background: white;
      }

      input[type="file"] {
        display: none;
      }

      .custom-file-upload {
        border-radius: 0.5rem;
        border: solid 0.1rem;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        cursor: pointer;
      }

      .custom-file-upload-disable {
        border-radius: 0.5rem;
        border: solid 0.1rem grey;
        display: inline-block;
        padding: 6px 12px;
        margin-left: 0.4rem;
        margin-right: 0.4rem;
        background-color: white;
        color: grey;
      }

      .item-cargos {
        display: grid;
        margin: 1rem 0;
        width: -webkit-fill-available;

        /* margin: 1rem 0; */
        label {
          margin: 0;
          display: inline;
          font-family: "Montserrat-Medium";
          font-size: 0.9rem;
        }

        input {
          height: 2rem;
          font-family: "Montserrat-Medium";
          padding-left: 0.5rem;
          font-size: 0.9rem;
        }

        a {
          color: #ffa600;
        }

        a:hover {
          color: #e49503;
        }

        .aaa {
          color: #efefef;
        }
      }
    }
  }
</style>
