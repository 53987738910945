import axios from "axios";
import axiosInstance from "./axiosInstance";

const queryString = require("query-string");

class ConvenioCotaApi {
  cancelTokens = {
    getConveniosCotas: null,
    getConvenioCota: null,
    setConveniosCotas: null,
    setConvenioCotaFaixas: null,
    destroyFaixa: null,
    getFaixasConvenios: null,
    getOrgaosConveniados: null,
    destroyConveniosCotas: null,
  };

  isCancel(exceptionThrown) {
    return axios.isCancel(exceptionThrown);
  }

  async getConveniosCotas({
    page = 1,
    qtd_por_pagina,
    nome,
    orgao_id,
    conveniosCotas,
  }) {
    const query = queryString.stringify({
      page,
      qtd_por_pagina,
      nome,
      orgao_id,
      conveniosCotas,
    });

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getConveniosCotas = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas?${query}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConveniosCotas = null;
    return data;
  }

  async getConvenioCota(convenioCotaId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getConvenioCota = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/${convenioCotaId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConvenioCota = null;

    return data;
  }

  async getFaixasConvenios(convenioCotaId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getFaixasConvenios = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/faixas_convenio/${convenioCotaId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getFaixasConvenios = null;
    return data;
  }

  async getOrgaosConveniados() {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.getOrgaosConveniados = source;

    const { data } = await axiosInstance.get(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/orgaos_conveniados`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getOrgaosConveniados = null;

    return data;
  }

  async destroyConveniosCotas(convenioCotaId) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.destroyConveniosCotas = source;

    const { data } = await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/${convenioCotaId}`,
      {
        cancelToken: source.token,
      }
    );

    this.cancelTokens.getConveniosCotas = null;
    return data;
  }

  async setConveniosCotas(convenioCota) {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    this.cancelTokens.setConveniosCotas = source;

    if (convenioCota.id) {
      const { data } = await axiosInstance.put(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/${convenioCota.id}`,
        {
          cancelToken: source.token,
          ...convenioCota,
        }
        /* ).catch(erro => {
        console.log(erro.response);
        return erro;
      } */
      );

      this.cancelTokens.setConveniosCotas = null;
      return data;
    } else {
      const { data } = await axiosInstance.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas`,
        {
          cancelToken: source.token,
          ...convenioCota,
        }
        //
      );

      this.cancelTokens.setConveniosCotas = null;
      return data;
    }
  }

  async setConvenioCotaFaixas(idConvenio, convenioCotaForm, convenioCotaFormm) {
    const faixas = convenioCotaFormm.faixas_convenios_attributes
      .filter((faixa) => faixa._destroy !== true)
      .map((faixa) => ({
        id: faixa.id,
        nome: faixa.nome,
        limite: faixa.limite,
        _destroy: faixa._destroy,
      }));

    const faixasParaDestruir = convenioCotaFormm.faixas_convenios_attributes
      .filter((faixa) => faixa._destroy == true)
      .map((faixa) => ({
        id: faixa.id,
        nome: faixa.nome,
        limite: faixa.limite,
        _destroy: faixa._destroy,
      }));  

    convenioCotaForm.faixas_convenios_attributes = faixas;

    faixasParaDestruir.map((faixa) => {
      this.destroyFaixa(faixa.id)
    })

    const { data } = await axiosInstance.put(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/convenios_cotas/${idConvenio}`,
      convenioCotaForm
    );

    return data;
  }

  async destroyFaixa(idFaixa) {
    await axiosInstance.delete(
      `${process.env.VUE_APP_BASE_API_URL}/api/v1/faixas_convenios/${idFaixa}`
    );
  }
}

export default ConvenioCotaApi;